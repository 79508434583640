var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { submitUserLogin } from '../../../api/userService';
import { useAuth } from '../../../contexts/AuthContext';
import { ButtonIcon } from '../../../types/FormModelTypes';
import MainContainer from '../MainContainer';
import Input from '../../common/Inputs/Input';
import SecondaryButton from '../../common/Buttons/SecondaryButton';
import texts from '../../../data/texts.json';
import { useErrorBoundary } from 'react-error-boundary';
import { log } from '../../../utils/logger';
const Login = () => {
    const [userInfo, setUserInfo] = useState({ username: null, password: null });
    const [errorMessage, setErrorMessage] = useState(false);
    const navigate = useNavigate();
    const { setToken } = useAuth();
    const { showBoundary } = useErrorBoundary();
    const handleDocumentId = (value) => {
        setUserInfo(Object.assign(Object.assign({}, userInfo), { username: value }));
        setErrorMessage(false);
    };
    const handlePassword = (value) => {
        setUserInfo(Object.assign(Object.assign({}, userInfo), { password: value }));
        setErrorMessage(false);
    };
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const loggedIn = yield submitUserLogin(userInfo);
            if (loggedIn) {
                setToken(loggedIn.accessToken);
                navigate('/form');
            }
            else {
                setErrorMessage(true);
            }
        }
        catch (e) {
            log.error('Error calling postUserLogin endpoint', e);
            showBoundary(e);
        }
    });
    return (React.createElement(MainContainer, { isCollectiveApiResponded: true },
        React.createElement(Helmet, null,
            React.createElement("title", null, "Signo | Login")),
        React.createElement("div", { className: "main-content login-page" },
            React.createElement("h1", { className: "login-page__title", "data-testid": "login-page-title" }, texts.loginPageTexts.pageTitle),
            React.createElement("div", { className: "login-page__body" },
                errorMessage && (React.createElement("div", { className: "error-message alert alert-danger", role: "alert" },
                    React.createElement("i", { className: "bi bi-exclamation-circle-fill" }),
                    React.createElement("span", null, texts.loginPageTexts.errorMessage))),
                React.createElement("div", { className: "login-page__body-inputs", "data-testid": "input-block" },
                    React.createElement(Input, { id: 'login', placeholder: texts.loginPageTexts.documentIdPlaceholder, label: texts.loginPageTexts.documentIdLabel, type: "text", onChangeFn: handleDocumentId, error: errorMessage }),
                    React.createElement(Input, { id: 'password', label: texts.loginPageTexts.passwordLabel, placeholder: texts.loginPageTexts.passwordPlaceholder, type: "password", onChangeFn: handlePassword, error: errorMessage }))),
            React.createElement(SecondaryButton, { text: texts.loginPageTexts.submitButton, onClickFn: handleSubmit, testId: "login-btn", enabled: !!userInfo.username && !!userInfo.password, submitButton: true, icon: ButtonIcon.ARROW }))));
};
export default Login;
