var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { log } from '../utils/logger';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const performAxiosRequest = (fn, handledErrorCodes = [], responseOnFail = null) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        log.debug('Try performing Axios Request');
        return yield fn();
    }
    catch (err) {
        log.debug('Catch performing Axios Request');
        if (err.response && handledErrorCodes.includes(err.response.status)) {
            log.error('Handling expected error', err);
            return responseOnFail;
        }
        else {
            log.error('Throwing unexpected error', err);
            throw err;
        }
    }
});
