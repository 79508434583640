var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { log } from '../utils/logger';
import { putOt } from './signoAPI';
import { performAxiosRequest } from './requestHelper';
const otOtrosValueText = new Map([
    ['procent_otros_c', 'Otros mecanismos de recogida'],
    ['procent_origen_c', 'Otro origen'],
    ['procent_verificaotros_c', 'Otro proceso de verificación'],
    ['procent_figuraotros_c', 'Otras figuras'],
    ['procalma_ubiotros_c', 'Otra ubicación'],
    ['procalma_figuraotros_c', 'Otras figuras'],
    ['procuso_otros_c', 'Otros mecanismos de autenticación'],
    ['procuso_figuraotros_c', 'Otras figuras'],
    ['procdest_figuraotros_c', 'Otras figuras'],
]);
export const publishOt = (userFormId, otId, response) => __awaiter(void 0, void 0, void 0, function* () {
    const body = composeEipdRequestBodyFromOtResponses(otId, response);
    log.debug('EipdBodyComposed =>', body);
    return performAxiosRequest(() => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield putOt(userFormId, otId, body);
        log.debug('USER SERVICEFORMFILLING/OT - response.data ==> ', response.data);
        return response.status === 200;
    }));
});
const composeActivosNode = (node, responses) => {
    const eipdActivos = [];
    const nodeEntries = responses
        .filter((r) => r.type === node)
        .map((r) => r.value)
        .filter((value) => Number(value))
        .map((value) => Number(value));
    nodeEntries.forEach((id) => {
        eipdActivos.push({
            activo: {
                Id: id,
            },
            triggered_computed_value: 'by user',
        });
    });
    return eipdActivos;
};
const composeEntryNode = (node, responses) => {
    const nodeEntries = responses
        .filter((r) => r.type === node)
        .map((r) => r.value)
        .filter((entry) => !Number(entry));
    const entries = {};
    nodeEntries.concat([`${node}_c`]).forEach((input) => {
        if (otOtrosValueText.has(input)) {
            entries[input] = otOtrosValueText.get(input);
        }
        else {
            entries[input] = true;
        }
    });
    return entries;
};
export const composeEipdRequestBodyFromOtResponses = (otId, responses) => {
    return {
        procent: composeEntryNode('procent', responses),
        procalma: composeEntryNode('procalma', responses),
        procuso: composeEntryNode('procuso', responses),
        procdest: composeEntryNode('procdest', responses),
        relations: {
            rel: [
                {
                    ot: {
                        Id: otId,
                        IsWeb: false,
                        OT_Obligatoria_c: false,
                        CONVERSIA_OpTratamientoDefaultValues_Id_c: 0,
                    },
                    procent: composeActivosNode('procent', responses),
                    procalma: composeActivosNode('procalma', responses),
                    procuso: composeActivosNode('procuso', responses),
                    procdest: composeActivosNode('procdest', responses),
                },
            ],
        },
    };
};
