export var DocumentType;
(function (DocumentType) {
    DocumentType["PDD"] = "pdd";
    DocumentType["CPS"] = "cps";
})(DocumentType || (DocumentType = {}));
export var DocumentationAlertMessage;
(function (DocumentationAlertMessage) {
    DocumentationAlertMessage["GENERATING"] = "documents under generation";
    DocumentationAlertMessage["NOTFOUND"] = "documents are not found";
    DocumentationAlertMessage["UNAUTHORIZED"] = "unauthorized access";
})(DocumentationAlertMessage || (DocumentationAlertMessage = {}));
