import React from 'react';
import texts from '../../../../data/texts.json';
import DisclaimerContainer from '../../DisclaimerContainer';
const PublicAdministrationDisclaimer = ({ goBack, collectivePersonalization }) => {
    return (React.createElement(DisclaimerContainer, { collectivePersonalization: collectivePersonalization },
        React.createElement("h1", { className: "disclaimer-page__title", "data-testid": "public-admin-title" }, texts.registrationPageTexts.publicAdminScreen.title),
        React.createElement("div", { className: "disclaimer-page__description", "data-testid": "public-admin-description" }, texts.registrationPageTexts.publicAdminScreen.description),
        React.createElement("div", { className: "disclaimer-page__additional-text", "data-testid": "public-admin-additional-text" }, texts.registrationPageTexts.publicAdminScreen.additionalText),
        React.createElement("div", { className: "disclaimer-page__go_back_link" },
            React.createElement("a", { onClick: goBack, "data-testid": "public-admin-go-back-link" }, texts.registrationPageTexts.publicAdminScreen.goBackLink))));
};
export default PublicAdministrationDisclaimer;
