import React, { useContext } from 'react';
import texts from '../../../../../data/texts.json';
import FormResponseContext from '../../../../../contexts/FormResponseContext';
const IterativeResumeItem = ({ index, item, iterativeContentParentId }) => {
    const { removeIterativeItem, getUserFormId } = useContext(FormResponseContext);
    return (React.createElement("div", { className: "card" },
        React.createElement("div", { className: "card-header" },
            React.createElement("div", { className: "card-header__text", "data-testid": `iterative-items-${index}` },
                item.value,
                ' ',
                item.additionalInfo && (React.createElement("span", { className: "card-header__text-additional-info", "data-testid": `iterative-items-additional-info-${index}` },
                    ' ',
                    "- ",
                    item.additionalInfo))),
            React.createElement("button", { className: "button--delete", "data-testid": `delete-button-${index}`, onClick: () => {
                    removeIterativeItem(item.contentId, item.value, iterativeContentParentId, getUserFormId());
                } },
                React.createElement("div", { className: "button--delete__text" },
                    texts.iterativeResumeTexts.delete.toUpperCase(),
                    " "),
                React.createElement("i", { className: "bi bi-trash" })))));
};
export default IterativeResumeItem;
