import React from 'react';
import MultiContentBinaryQuestion from './Questions/MultiContentBinaryQuestion';
import MultiContentMultipleQuestion from './Questions/MultiContentMultipleQuestion';
import { ContentType, DropdownContentSubtype } from '../../../../../types/FormModelTypes';
import TextQuestion from '../CommonQuestions/TextQuestion';
import DropdownQuestion from '../CommonQuestions/DropdownQuestion';
import DropdownProvinceQuestion from '../CommonQuestions/DropdownProvinceQuestion';
const MultiContentQuestions = ({ content, selectedResponses, handleSelectedResponses }) => {
    const handleOnChange = (response) => {
        const newResponses = [...selectedResponses];
        const existingResponse = newResponses.find((r) => r.contentId === response.contentId);
        response.contentValue !== ''
            ? newResponses.splice(newResponses.indexOf(existingResponse), existingResponse ? 1 : 0, response)
            : newResponses.splice(newResponses.indexOf(existingResponse), 1);
        handleSelectedResponses(newResponses);
    };
    const handleOnClickBinary = (response) => {
        const newResponses = [...selectedResponses];
        const newResponsesFiltered = newResponses.filter((resp) => resp.contentId !== response.contentId);
        const existingResponse = newResponsesFiltered.find((r) => r.contentId === response.contentId);
        newResponsesFiltered.splice(newResponsesFiltered.indexOf(existingResponse), existingResponse ? 1 : 0, response);
        handleSelectedResponses(newResponsesFiltered);
    };
    const handleOnClickMultiple = (response) => {
        let newResponses = [...selectedResponses];
        const existingResponse = newResponses.find((r) => r.contentId === response.contentId);
        if (existingResponse) {
            if (existingResponse.ocs_variables.includes(response.ocs_variable)) {
                existingResponse.ocs_variables.splice(existingResponse.ocs_variables.indexOf(response.ocs_variable), 1);
                if (existingResponse.ocs_variables.length <= 0) {
                    newResponses = newResponses.filter((r) => r.contentId !== existingResponse.contentId);
                }
            }
            else {
                existingResponse.ocs_variables.push(response.ocs_variable);
            }
        }
        else {
            const newResponse = {
                contentId: response.contentId,
                valueType: response.valueType,
                ocs_variables: [response.ocs_variable],
                ocs_entity: response.ocs_entity,
            };
            newResponses.push(newResponse);
        }
        handleSelectedResponses(newResponses);
    };
    const getSelectedResponseById = (id) => {
        return selectedResponses.find((response) => response.contentId === id);
    };
    return (React.createElement("div", { className: "multi-content__questions" }, content.content.map((multiContentItem) => {
        switch (multiContentItem.type) {
            case ContentType.TEXT:
                return React.createElement(TextQuestion, { key: multiContentItem.id, content: multiContentItem, handleOnChange: handleOnChange });
            case ContentType.BINARY:
                return (React.createElement(MultiContentBinaryQuestion, { key: multiContentItem.id, content: multiContentItem, handleOnClick: handleOnClickBinary, selectedValue: getSelectedResponseById(multiContentItem.id) }));
            case ContentType.MULTIPLE:
                return (React.createElement(MultiContentMultipleQuestion, { key: multiContentItem.id, content: multiContentItem, selectedValue: getSelectedResponseById(multiContentItem.id), handleOnClick: handleOnClickMultiple }));
            case ContentType.DROPDOWN:
                switch (multiContentItem.subtype) {
                    case DropdownContentSubtype.PROVINCE:
                        return React.createElement(DropdownProvinceQuestion, { key: multiContentItem.id, handleOnChange: handleOnClickBinary, content: multiContentItem });
                    default:
                        return React.createElement(DropdownQuestion, { key: multiContentItem.id, handleOnChange: handleOnClickBinary, content: multiContentItem });
                }
        }
    })));
};
export default MultiContentQuestions;
