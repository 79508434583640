import React from 'react';
export var VerticalLinePositionEnum;
(function (VerticalLinePositionEnum) {
    VerticalLinePositionEnum[VerticalLinePositionEnum["UP"] = 0] = "UP";
    VerticalLinePositionEnum[VerticalLinePositionEnum["DOWN"] = 1] = "DOWN";
    VerticalLinePositionEnum[VerticalLinePositionEnum["MIDDLE"] = 2] = "MIDDLE";
})(VerticalLinePositionEnum || (VerticalLinePositionEnum = {}));
const VerticalLine = ({ transparent, position, sectionCompleted, previousSectionCompleted }) => {
    let baseClassName;
    switch (position) {
        case VerticalLinePositionEnum.UP:
            baseClassName = 'menu__vl--start';
            break;
        case VerticalLinePositionEnum.DOWN:
            baseClassName = 'menu__vl--end';
            break;
        default:
            baseClassName = '';
            break;
    }
    return (React.createElement("div", { className: `${baseClassName} 
      ${transparent ? 'menu__vl--transparent' : 'menu__vl'}
      ${!transparent && position == VerticalLinePositionEnum.DOWN && sectionCompleted ? 'menu__vl--completed' : ''}
      ${!transparent && position == VerticalLinePositionEnum.UP && previousSectionCompleted ? 'menu__vl--completed' : ''}` }));
};
export default VerticalLine;
