import React, { useContext, useEffect, useState } from 'react';
import FormProgressContext, { FormStatus } from '../../../../contexts/FormProgressContext';
import ContactInfoButton from './ContactInfoButton';
import InfoModal from '../InfoModal';
import texts from '../../../../data/texts.json';
import UserContext from '../../../../contexts/UserContext';
const ContactInfoModalWithResetLink = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const [showResetLink, setShowResetLink] = useState(false);
    const { getFormProgressStatus } = useContext(FormProgressContext);
    const { getPersonalization } = useContext(UserContext);
    const getContactInfoTexts = () => {
        const personalization = getPersonalization();
        if (!personalization || !(personalization === null || personalization === void 0 ? void 0 : personalization.contactInfoModalWithResetLink)) {
            return null;
        }
        const contactInfoModalWithResetLink = personalization.contactInfoModalWithResetLink;
        return contactInfoModalWithResetLink;
    };
    const contactInfoModalContent = {
        title: (_b = (_a = getContactInfoTexts()) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : texts.modalsTexts.contactInfoModal.title,
        description: (_d = (_c = getContactInfoTexts()) === null || _c === void 0 ? void 0 : _c.description) !== null && _d !== void 0 ? _d : texts.modalsTexts.contactInfoModal.description,
        additionalText: (_f = (_e = getContactInfoTexts()) === null || _e === void 0 ? void 0 : _e.additionalText) !== null && _f !== void 0 ? _f : texts.modalsTexts.contactInfoModal.additionalText,
        buttonText: (_h = (_g = getContactInfoTexts()) === null || _g === void 0 ? void 0 : _g.buttonText) !== null && _h !== void 0 ? _h : texts.modalsTexts.contactInfoModal.buttonText,
        resetForm: {
            text: (_l = (_k = (_j = getContactInfoTexts()) === null || _j === void 0 ? void 0 : _j.resetForm) === null || _k === void 0 ? void 0 : _k.text) !== null && _l !== void 0 ? _l : texts.modalsTexts.contactInfoModal.resetForm.text,
            link: (_p = (_o = (_m = getContactInfoTexts()) === null || _m === void 0 ? void 0 : _m.resetForm) === null || _o === void 0 ? void 0 : _o.link) !== null && _p !== void 0 ? _p : texts.modalsTexts.contactInfoModal.resetForm.link,
        },
    };
    const alertContent = {
        title: texts.modalsTexts.resetFormAlert.title,
        body: texts.modalsTexts.resetFormAlert.body,
        closeButton: texts.modalsTexts.resetFormAlert.closeButton,
        actionButton: texts.modalsTexts.resetFormAlert.actionButton,
    };
    useEffect(() => {
        setShowResetLink(getFormProgressStatus() === FormStatus.IN_PROGRESS);
    }, [getFormProgressStatus]);
    return (React.createElement("div", { className: "modal-container contact-info", "data-testid": "contact-info-modal" },
        React.createElement(ContactInfoButton, { modalType: "contact-info-modal" }),
        React.createElement(InfoModal, { modalContent: contactInfoModalContent, modalType: "contact-info-modal", showResetLink: showResetLink, alertContent: alertContent })));
};
export default ContactInfoModalWithResetLink;
