import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import texts from '../../../../data/texts.json';
import InfoModal from '../InfoModal';
import ContactInfoButton from './ContactInfoButton';
import { fetchCollectiveId } from '../../../../api/collectiveService';
import { log } from '../../../../utils/logger';
const ContactInfoModal = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const [searchParams] = useSearchParams();
    const collectiveHash = searchParams.get('c');
    const [collectivePersonalization, setCollectivePersonalization] = useState(null);
    const [collectiveId, setCollectiveId] = useState(null);
    useEffect(() => {
        if (collectiveHash !== null && collectiveId === null) {
            fetchCollectiveId(collectiveHash)
                .then((response) => {
                if (response !== null) {
                    setCollectiveId(response.id);
                    setCollectivePersonalization(response.personalization);
                }
            })
                .catch(log.warn);
        }
    }, [collectiveHash, collectiveId]);
    const contactModalContent = {
        title: (_b = (_a = collectivePersonalization === null || collectivePersonalization === void 0 ? void 0 : collectivePersonalization.contactInfoModal) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : texts.modalsTexts.contactInfoModal.title,
        description: (_d = (_c = collectivePersonalization === null || collectivePersonalization === void 0 ? void 0 : collectivePersonalization.contactInfoModal) === null || _c === void 0 ? void 0 : _c.description) !== null && _d !== void 0 ? _d : texts.modalsTexts.contactInfoModal.description,
        additionalText: (_f = (_e = collectivePersonalization === null || collectivePersonalization === void 0 ? void 0 : collectivePersonalization.contactInfoModal) === null || _e === void 0 ? void 0 : _e.additionalText) !== null && _f !== void 0 ? _f : texts.modalsTexts.contactInfoModal.additionalText,
        buttonText: (_h = (_g = collectivePersonalization === null || collectivePersonalization === void 0 ? void 0 : collectivePersonalization.contactInfoModal) === null || _g === void 0 ? void 0 : _g.buttonText) !== null && _h !== void 0 ? _h : texts.modalsTexts.contactInfoModal.buttonText,
    };
    return (React.createElement("div", { className: "modal-container contact-info", "data-testid": "contact-info-modal" },
        React.createElement(ContactInfoButton, { modalType: "contact-info-modal" }),
        React.createElement(InfoModal, { modalContent: contactModalContent, modalType: "contact-info-modal" })));
};
export default ContactInfoModal;
