import React, { createContext, useContext, useEffect, useState } from 'react';
import { log } from '../utils/logger';
import { calculateIsDpdMandatory } from '../api/dpdService';
import FormProgressContext from './FormProgressContext';
import FormResponseContext from './FormResponseContext';
import { useErrorBoundary } from 'react-error-boundary';
const DpdContext = createContext(null);
export const DpdContextProvider = ({ children, dpdIsMandatory = null }) => {
    const [isDpdMandatory, setIsDpdMandatory] = useState(dpdIsMandatory);
    const { getCurrentSectionId } = useContext(FormProgressContext);
    const { getUserFormId } = useContext(FormResponseContext);
    const { showBoundary } = useErrorBoundary();
    useEffect(() => {
        const sectionsBeforeDpdCalculation = ['formStart', 's1', 's1.1'];
        // if current section id is past section s1.1, calculate if DPD is mandatory and save it in context
        if (!sectionsBeforeDpdCalculation.includes(getCurrentSectionId())) {
            calculateIsDpdMandatory(getUserFormId())
                .then((res) => {
                setIsDpdMandatory(res);
            })
                .catch((err) => {
                log.error('Error calling getIsDpdMandatoryResult endpoint', err);
                showBoundary(err);
            });
        }
    }, [getCurrentSectionId, getUserFormId, isDpdMandatory, showBoundary]);
    const getIsDpdMandatory = () => {
        return isDpdMandatory;
    };
    const saveIsDpdMandatory = (isDpdMandatory) => {
        setIsDpdMandatory(isDpdMandatory);
    };
    const value = {
        getIsDpdMandatory,
        saveIsDpdMandatory,
    };
    return React.createElement(DpdContext.Provider, { value: value }, children);
};
export default DpdContext;
