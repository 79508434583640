var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useContext, useEffect, useState } from 'react';
import NextButton from '../../../../common/Buttons/NextButton';
import { ContentType, DropdownContentSubtype } from '../../../../../types/FormModelTypes';
import EipdContext from '../../../../../contexts/EipdContext';
import MultiContentBinaryQuestion from './Questions/MultiContentBinaryQuestion';
import DropdownQuestion from '../CommonQuestions/DropdownQuestion';
import { log } from '../../../../../utils/logger';
import DropdownActivosQuestion from '../CommonQuestions/DropdownActivosQuestion';
import { useErrorBoundary } from 'react-error-boundary';
const MultiContentOTs = ({ content, goToNextContent }) => {
    const { getCurrentOt, saveOtsResponses, removeOtsResponses, publishOtsResponses } = useContext(EipdContext);
    const [selectedOtsResponses, setSelectedOtsResponses] = useState([]);
    const [isProcalmaIsPaperStoredButtonClicked, setIsProcalmaIsPaperStoredButtonClicked] = useState(false);
    const [isFormCompleted, setIsFormCompleted] = useState(false);
    const procalmaIsPaperStoredEntity = 'procalma_is_paper_stored';
    const procalmaMandatoryEntities = ['procalma_electronic_support', 'procalma_data_location', 'procalma_figures', 'procalma_actives'];
    const procalmaOtPageIdentifier = 's6-c9';
    const { showBoundary } = useErrorBoundary();
    useEffect(() => {
        const sendForm = () => __awaiter(void 0, void 0, void 0, function* () {
            const wasSuccessful = yield publishOtsResponses();
            if (!wasSuccessful) {
                showBoundary('Error publishing OT');
            }
            else {
                goToNextContent(content.nextButton.linkTo);
            }
        });
        if (isFormCompleted) {
            sendForm().then(() => log.debug('Send form operation finished'));
        }
    }, [content.nextButton.linkTo, goToNextContent, isFormCompleted, publishOtsResponses, showBoundary]);
    const getTitle = () => {
        var _a;
        return content.title.replace(/\[.*?\]/g, (_a = getCurrentOt()) === null || _a === void 0 ? void 0 : _a.title);
    };
    const handleOnClick = (response) => {
        setIsProcalmaIsPaperStoredButtonClicked(true);
        const selectedResponses = [...selectedOtsResponses];
        const existingResponse = selectedResponses.find((r) => r.entity === procalmaIsPaperStoredEntity);
        if (!existingResponse && response.contentValue !== 'No') {
            log.debug('Selected YES and the response had not been saved yet => saving it');
            const otEntityResponse = {
                entity: response.ocs_entity,
                otResponses: [
                    {
                        type: response.ocs_variable,
                        value: response.contentValue,
                    },
                ],
            };
            setSelectedOtsResponses([...selectedOtsResponses, otEntityResponse]);
        }
        else if (existingResponse && response.contentValue === 'No') {
            log.debug('Had previously selected YES and then selected NO => removing it');
            selectedResponses
                .filter((r) => !procalmaMandatoryEntities.includes(r.entity))
                .forEach((foundResponse) => {
                const responseToDelete = selectedResponses.find((r) => r.entity === foundResponse.entity);
                selectedResponses.splice(selectedResponses.indexOf(responseToDelete), 1);
            });
            setSelectedOtsResponses(selectedResponses);
        }
    };
    const handleOnChangeMultiple = (responses) => {
        const selectedResponses = [...selectedOtsResponses];
        const entity = responses[0].ocs_entity;
        const existingResponse = selectedOtsResponses.find((r) => r.entity === entity);
        const wereAllResponsesFromTheCurrentInputRemoved = responses.find((r) => r.contentValue === null);
        if (existingResponse) {
            selectedResponses.splice(selectedResponses.indexOf(existingResponse), 1);
        }
        if (wereAllResponsesFromTheCurrentInputRemoved) {
            setSelectedOtsResponses(selectedResponses);
        }
        else {
            setSelectedOtsResponses([
                ...selectedResponses,
                {
                    entity: entity,
                    otResponses: responses.map((response) => {
                        return { type: response.ocs_variable, value: response.contentValue };
                    }),
                },
            ]);
        }
    };
    const handleCancel = () => {
        setSelectedOtsResponses([]); // emptying the local MultiContentOTs state
        removeOtsResponses(); // remove content from EipdContext as well
        goToNextContent(content.cancelButton.linkTo);
    };
    const saveAndGoToNextContent = () => {
        const otsResponses = [...selectedOtsResponses.flatMap((otEntityResponse) => otEntityResponse.otResponses).values()];
        saveOtsResponses(otsResponses);
        setSelectedOtsResponses([]);
        if (content.nextButton.title === 'Completar') {
            setIsFormCompleted(true);
        }
        else {
            goToNextContent(content.nextButton.linkTo);
        }
    };
    const isNextButtonEnabled = () => {
        const numberOfInputsInThePage = content.content.length;
        const numberOfInputsWithResponses = selectedOtsResponses.flatMap((r) => r.entity).length;
        const isProcalmaOtPage = content.id === procalmaOtPageIdentifier;
        const isProcalmaIsPaperStoredButtonClickedAsYes = selectedOtsResponses.find((r) => r.entity === procalmaIsPaperStoredEntity);
        const hasProcalmaMandatoryEntitiesFulfilled = selectedOtsResponses.filter((r) => procalmaMandatoryEntities.includes(r.entity)).length === procalmaMandatoryEntities.length;
        if (isProcalmaOtPage &&
            isProcalmaIsPaperStoredButtonClicked &&
            !isProcalmaIsPaperStoredButtonClickedAsYes &&
            hasProcalmaMandatoryEntitiesFulfilled) {
            return true;
        }
        return numberOfInputsInThePage === numberOfInputsWithResponses;
    };
    const isInputDisabled = (multiContentItemId) => {
        const isProcalmaOtPage = content.id === procalmaOtPageIdentifier;
        const isProcalmaIsPaperStoredButtonClickedAsYes = selectedOtsResponses.find((r) => r.entity === procalmaIsPaperStoredEntity);
        if (isProcalmaOtPage && !isProcalmaIsPaperStoredButtonClickedAsYes) {
            const procalmaSectionsThatShouldBeAlwaysEnabled = ['s6-c9-c1', 's6-c9-c2', 's6-c9-c3', 's6-c9-c4'];
            return !procalmaSectionsThatShouldBeAlwaysEnabled.includes(multiContentItemId);
        }
        return false;
    };
    const handleBinaryButtonIsSelected = (content) => {
        const isProcalmaIsPaperStoredButtonClickedAsYes = selectedOtsResponses.find((r) => r.entity === procalmaIsPaperStoredEntity);
        const contentValue = (function () {
            switch (isProcalmaIsPaperStoredButtonClicked) {
                case true:
                    return isProcalmaIsPaperStoredButtonClickedAsYes ? 'procalma_conservacionpapel_c' : 'No';
                case false:
                    return '';
            }
        })();
        const response = {
            valueType: ContentType.BINARY,
            contentId: content.id,
            contentValue: contentValue,
        };
        return response;
    };
    log.debug('selectedOtsResponses from component state ---> ', selectedOtsResponses);
    return (React.createElement("div", { className: "multi-content" },
        React.createElement("div", { className: "multi-content__body" },
            React.createElement("h1", { className: `multi-content__title ${content.description && 'no-padding-top'}`, "data-testid": "multi-content-title" }, getTitle()),
            content.description && React.createElement("h2", { className: "multi-content__description ot-title" }, content.description),
            React.createElement("div", null, content.additionalInformation && (React.createElement("div", { className: "multi-content__additional-information" },
                React.createElement("p", null, content.additionalInformation)))),
            React.createElement("div", { className: "multi-content__questions" }, content.content.map((multiContentItem) => {
                switch (multiContentItem.type) {
                    case ContentType.BINARY:
                        return (React.createElement(MultiContentBinaryQuestion, { key: multiContentItem.id, content: multiContentItem, handleOnClick: handleOnClick, selectedValue: handleBinaryButtonIsSelected(multiContentItem) }));
                    case ContentType.DROPDOWN:
                        if (isInputDisabled(multiContentItem.id)) {
                            break;
                        }
                        switch (multiContentItem.subtype) {
                            case DropdownContentSubtype.ACTIVOS:
                                return (React.createElement(DropdownActivosQuestion, { key: multiContentItem.id, handleOnChangeMultiple: handleOnChangeMultiple, content: multiContentItem, isOptionDisable: isInputDisabled(multiContentItem.id) }));
                            default:
                                return (React.createElement(DropdownQuestion, { key: multiContentItem.id, handleOnChangeMultiple: handleOnChangeMultiple, content: multiContentItem, isOptionDisable: isInputDisabled(multiContentItem.id) }));
                        }
                }
            }))),
        React.createElement("div", { className: "multi-content__next-action" },
            content.cancelButton && (React.createElement("button", { onClick: handleCancel, className: "button--cancel", "data-testid": "cancel-button" }, content.cancelButton.title.toUpperCase())),
            React.createElement(NextButton, { onClickFn: saveAndGoToNextContent, enabled: isNextButtonEnabled(), text: content.nextButton.title.toUpperCase() }))));
};
export default MultiContentOTs;
