import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// Importing the Bootstrap CSS and icons
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
// Importing the custom CSS
import './scss/styles.scss';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(React.createElement(App, null));
