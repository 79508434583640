import React from 'react';
import DisclaimerContainer from '../DisclaimerContainer';
import texts from '../../../data/texts.json';
import { Helmet } from 'react-helmet';
const MaintenancePage = () => {
    const getDescription = () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return { __html: texts.maintenancePageTexts.deployingVersion.description };
    };
    const getSubdescription = () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return { __html: texts.maintenancePageTexts.deployingVersion.subdescription };
    };
    return (React.createElement(DisclaimerContainer, null,
        React.createElement(Helmet, null,
            React.createElement("title", null,
                "Signo | ",
                texts.maintenancePageTexts.pageTitle)),
        React.createElement("h1", { className: "disclaimer-page__title", "data-testid": "error-page-title" }, texts.maintenancePageTexts.pageTitle),
        React.createElement("div", { className: "disclaimer-page__description", "data-testid": "error-page-description", dangerouslySetInnerHTML: getDescription() }),
        getSubdescription() !== undefined && (React.createElement("div", { className: "disclaimer-page__subdescription", "data-testid": "error-page-subdescription", dangerouslySetInnerHTML: getSubdescription() }))));
};
export default MaintenancePage;
