import React from 'react';
import texts from '../../../../data/texts.json';
import DisclaimerContainer from '../../DisclaimerContainer';
import PrimaryButton from '../../../common/Buttons/PrimaryButton';
import LegalInfoModal from '../../../common/Modals/LegalInfo/LegalInfoModal';
const InitialRegistrationScreen = ({ goToNextStep, collectivePersonalization, isCollectiveApiResponded }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const cookiesModalContent = {
        title: texts.modalsTexts.cookiesModal.title,
        description: texts.modalsTexts.cookiesModal.description,
        buttonText: texts.modalsTexts.cookiesModal.buttonText,
    };
    return (React.createElement(DisclaimerContainer, { collectivePersonalization: collectivePersonalization, isCollectiveApiResponded: isCollectiveApiResponded },
        React.createElement("h1", { className: "disclaimer-page__title", "data-testid": "start-page-title" }, (_b = (_a = collectivePersonalization === null || collectivePersonalization === void 0 ? void 0 : collectivePersonalization.initialRegistrationScreen) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : texts.registrationPageTexts.initialRegistrationScreen.title),
        React.createElement("div", { className: "disclaimer-page__description initial-registration__description", dangerouslySetInnerHTML: { __html: (_d = (_c = collectivePersonalization === null || collectivePersonalization === void 0 ? void 0 : collectivePersonalization.initialRegistrationScreen) === null || _c === void 0 ? void 0 : _c.description) !== null && _d !== void 0 ? _d : texts.registrationPageTexts.initialRegistrationScreen.description } }),
        React.createElement("div", { className: "disclaimer-page__next initial-registration__next" },
            React.createElement("div", { className: "initial-registration__next-subtext" }, (_f = (_e = collectivePersonalization === null || collectivePersonalization === void 0 ? void 0 : collectivePersonalization.initialRegistrationScreen) === null || _e === void 0 ? void 0 : _e.additionalText) !== null && _f !== void 0 ? _f : texts.registrationPageTexts.initialRegistrationScreen.additionalText,
                React.createElement(LegalInfoModal, { modalContent: cookiesModalContent, linkText: 'aquí' })),
            React.createElement(PrimaryButton, { text: (_j = (_h = (_g = collectivePersonalization === null || collectivePersonalization === void 0 ? void 0 : collectivePersonalization.initialRegistrationScreen) === null || _g === void 0 ? void 0 : _g.cookiesButton) === null || _h === void 0 ? void 0 : _h.toUpperCase()) !== null && _j !== void 0 ? _j : texts.registrationPageTexts.initialRegistrationScreen.cookiesButton.toUpperCase(), onClickFn: goToNextStep, testId: 'cookies-button' })),
        React.createElement("p", { className: "initial-registration__login-redirect", dangerouslySetInnerHTML: { __html: (_l = (_k = collectivePersonalization === null || collectivePersonalization === void 0 ? void 0 : collectivePersonalization.initialRegistrationScreen) === null || _k === void 0 ? void 0 : _k.loginRedirect) !== null && _l !== void 0 ? _l : texts.registrationPageTexts.initialRegistrationScreen.loginRedirect } })));
};
export default InitialRegistrationScreen;
