import React, { useContext, useEffect, useState } from 'react';
import texts from '../../../../../data/texts.json';
import BinaryQuestion from './Questions/BinaryQuestion';
import { ContentType } from '../../../../../types/FormModelTypes';
import FormResponseContext from '../../../../../contexts/FormResponseContext';
import MultipleQuestion from './Questions/MultipleQuestion';
import NextButton from '../../../../common/Buttons/NextButton';
import TextQuestion from '../CommonQuestions/TextQuestion';
import { INFO_ICON_BLUE_URL } from '../../../../../utils/constants';
import FormProgressContext from '../../../../../contexts/FormProgressContext';
const SingleContentContainer = ({ content, goToNextContent, alertContent }) => {
    var _a, _b;
    const [selectedResponse, setSelectedResponse] = useState(null);
    const { saveFormResponse, getUserFormId } = useContext(FormResponseContext);
    const { getCurrentSectionId } = useContext(FormProgressContext);
    useEffect(() => {
        setSelectedResponse(content.formResponse);
    }, [content.formResponse]);
    const handleOnClick = (responseValue) => {
        var _a;
        responseValue.navBackLinkTo = content === null || content === void 0 ? void 0 : content.navBackLinkTo;
        responseValue.navNextLinkTo = (_a = content === null || content === void 0 ? void 0 : content.nextButton) === null || _a === void 0 ? void 0 : _a.linkTo;
        setSelectedResponse(responseValue);
    };
    const handleOnChange = (response) => {
        var _a;
        response.navBackLinkTo = content === null || content === void 0 ? void 0 : content.navBackLinkTo;
        response.navNextLinkTo = (_a = content === null || content === void 0 ? void 0 : content.nextButton) === null || _a === void 0 ? void 0 : _a.linkTo;
        response.contentValue === '' ? setSelectedResponse(null) : setSelectedResponse(response);
    };
    const renderInfoText = (questionType, questionIsRequired) => {
        return questionType === ContentType.MULTIPLE
            ? questionIsRequired
                ? texts.responsesInfoTexts.multipleQuestionRequired
                : texts.responsesInfoTexts.multipleQuestionNotRequired
            : questionType === ContentType.TEXT
                ? questionIsRequired
                    ? texts.responsesInfoTexts.textQuestionRequired
                    : texts.responsesInfoTexts.textQuestionNotRequired
                : null;
    };
    const canGoToNextContent = !content.questionRequired ||
        content.type === ContentType.BINARY ||
        (content.type === ContentType.TEXT && content.questionRequired && !!selectedResponse) ||
        (content.type === ContentType.MULTIPLE && ((_a = selectedResponse === null || selectedResponse === void 0 ? void 0 : selectedResponse.ocs_variables) === null || _a === void 0 ? void 0 : _a.length) > 0);
    const handleNextContent = () => {
        var _a;
        selectedResponse && saveFormResponse(selectedResponse, getUserFormId(), content.title, getCurrentSectionId());
        setSelectedResponse(null);
        goToNextContent((_a = content === null || content === void 0 ? void 0 : content.nextButton) === null || _a === void 0 ? void 0 : _a.linkTo);
    };
    const renderQuestion = () => {
        switch (content === null || content === void 0 ? void 0 : content.type) {
            case ContentType.BINARY:
                return React.createElement(BinaryQuestion, { content: content, goToNextContent: goToNextContent, alertContent: alertContent });
            case ContentType.MULTIPLE:
                return React.createElement(MultipleQuestion, { content: content, handleOnClick: handleOnClick });
            case ContentType.TEXT:
                return React.createElement(TextQuestion, { content: content, handleOnChange: handleOnChange });
            default:
        }
    };
    const questionDescription = { __html: content.description };
    return (React.createElement("div", { className: "question", "data-testid": "question-component" },
        React.createElement("div", { className: "question__body" },
            React.createElement("div", { "data-testid": "content-title", className: "question__title" }, content.title),
            ((_b = content.description) === null || _b === void 0 ? void 0 : _b.length) > 0 && React.createElement("div", { className: "question__description", dangerouslySetInnerHTML: questionDescription })),
        React.createElement("div", null,
            renderQuestion(),
            content.nextButton && (React.createElement("div", { className: "question__next-action" },
                React.createElement("div", { className: "question__info" },
                    React.createElement("p", { className: "question__info-text", "data-testid": "question-info-text" },
                        React.createElement("img", { className: "question__info-icon", alt: "Info icon", src: INFO_ICON_BLUE_URL }),
                        renderInfoText(content.type, content.questionRequired))),
                React.createElement(NextButton, { onClickFn: handleNextContent, text: content.nextButton.title.toUpperCase(), enabled: canGoToNextContent }))))));
};
export default SingleContentContainer;
