export const provincesValues = [
    {
        order: 1,
        text: 'Álava/Araba',
        value: 'Álava',
    },
    {
        order: 2,
        text: 'Albacete',
        value: 'Albacete',
    },
    {
        order: 3,
        text: 'Alicante',
        value: 'Alicante',
    },
    {
        order: 4,
        text: 'Almería',
        value: 'Almería',
    },
    {
        order: 5,
        text: 'Asturias',
        value: 'Asturias',
    },
    {
        order: 6,
        text: 'Ávila',
        value: 'Ávila',
    },
    {
        order: 7,
        text: 'Badajoz',
        value: 'Badajoz',
    },
    {
        order: 8,
        text: 'Baleares',
        value: 'Baleares',
    },
    {
        order: 9,
        text: 'Barcelona',
        value: 'Barcelona',
    },
    {
        order: 10,
        text: 'Burgos',
        value: 'Burgos',
    },
    {
        order: 11,
        text: 'Cáceres',
        value: 'Cáceres',
    },
    {
        order: 12,
        text: 'Cádiz',
        value: 'Cádiz',
    },
    {
        order: 13,
        text: 'Cantabria',
        value: 'Cantabria',
    },
    {
        order: 14,
        text: 'Castellón',
        value: 'Castellón',
    },
    {
        order: 15,
        text: 'Ceuta',
        value: 'Ceuta',
    },
    {
        order: 16,
        text: 'Ciudad Real',
        value: 'Ciudad Real',
    },
    {
        order: 17,
        text: 'Córdoba',
        value: 'Córdoba',
    },
    {
        order: 18,
        text: 'Cuenca',
        value: 'Cuenca',
    },
    {
        order: 19,
        text: 'Gerona/Girona',
        value: 'Gerona',
    },
    {
        order: 20,
        text: 'Granada',
        value: 'Granada',
    },
    {
        order: 21,
        text: 'Guadalajara',
        value: 'Guadalajara',
    },
    {
        order: 22,
        text: 'Guipúzcoa/Gipuzkoa',
        value: 'Guipúzcoa',
    },
    {
        order: 23,
        text: 'Huelva',
        value: 'Huelva',
    },
    {
        order: 24,
        text: 'Huesca',
        value: 'Huesca',
    },
    {
        order: 25,
        text: 'Jaén',
        value: 'Jaén',
    },
    {
        order: 25,
        text: 'La Coruña/A Coruña',
        value: 'La Coruña',
    },
    {
        order: 26,
        text: 'La Rioja',
        value: 'La Rioja',
    },
    {
        order: 27,
        text: 'Las Palmas',
        value: 'Las Palmas',
    },
    {
        order: 28,
        text: 'León',
        value: 'León',
    },
    {
        order: 28,
        text: 'Lérida/Lleida',
        value: 'Lérida',
    },
    {
        order: 29,
        text: 'Lugo',
        value: 'Lugo',
    },
    {
        order: 30,
        text: 'Madrid',
        value: 'Madrid',
    },
    {
        order: 31,
        text: 'Málaga',
        value: 'Málaga',
    },
    {
        order: 32,
        text: 'Melilla',
        value: 'Melilla',
    },
    {
        order: 33,
        text: 'Murcia',
        value: 'Murcia',
    },
    {
        order: 34,
        text: 'Navarra',
        value: 'Navarra',
    },
    {
        order: 35,
        text: 'Orense/Ourense',
        value: 'Orense',
    },
    {
        order: 36,
        text: 'Palencia',
        value: 'Palencia',
    },
    {
        order: 37,
        text: 'Pontevedra',
        value: 'Pontevedra',
    },
    {
        order: 38,
        text: 'Salamanca',
        value: 'Salamanca',
    },
    {
        order: 39,
        text: 'Segovia',
        value: 'Segovia',
    },
    {
        order: 40,
        text: 'Sevilla',
        value: 'Sevilla',
    },
    {
        order: 41,
        text: 'Soria',
        value: 'Soria',
    },
    {
        order: 42,
        text: 'Tarragona',
        value: 'Tarragona',
    },
    {
        order: 43,
        text: 'Tenerife',
        value: 'Tenerife',
    },
    {
        order: 44,
        text: 'Teruel',
        value: 'Teruel',
    },
    {
        order: 45,
        text: 'Toledo',
        value: 'Toledo',
    },
    {
        order: 46,
        text: 'Valencia',
        value: 'Valencia',
    },
    {
        order: 47,
        text: 'Valladolid',
        value: 'Valladolid',
    },
    {
        order: 48,
        text: 'Vizcaya/Bizkaia',
        value: 'Vizcaya',
    },
    {
        order: 49,
        text: 'Zamora',
        value: 'Zamora',
    },
    {
        order: 50,
        text: 'Zaragoza',
        value: 'Zaragoza',
    },
];
