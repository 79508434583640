var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import RegistrationTextQuestion from './Questions/RegistrationTextQuestion';
import LegalInfoModal from '../../../common/Modals/LegalInfo/LegalInfoModal';
import texts from '../../../../data/texts.json';
import { doPasswordValidation } from '../../../../api/userService';
import { useErrorBoundary } from 'react-error-boundary';
import { log } from '../../../../utils/logger';
const LoginInformation = ({ saveResponse, handleNextButton, nif, collectivePersonalization }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [termsOfUseAreAccepted, setTermsOfUseAreAccepted] = useState(false);
    const [errorPasswordsAreDifferentMessage, setErrorPasswordsAreDifferentMessage] = useState(false);
    const [errorPasswordIsNotValidMessage, setErrorPasswordIsNotValidMessage] = useState(false);
    const { showBoundary } = useErrorBoundary();
    useEffect(() => {
        const validatePassword = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const isPasswordValid = yield doPasswordValidation(password);
                setErrorPasswordIsNotValidMessage(!isPasswordValid);
            }
            catch (e) {
                showBoundary(e);
            }
        });
        if (passwordConfirmation !== '' && passwordConfirmation !== password) {
            setErrorPasswordsAreDifferentMessage(true);
        }
        else {
            setErrorPasswordsAreDifferentMessage(false);
        }
        if (password === '') {
            setErrorPasswordIsNotValidMessage(false);
        }
        else {
            validatePassword().then(() => log.debug('Validate password call finished'));
        }
        if (!errorPasswordsAreDifferentMessage &&
            !errorPasswordIsNotValidMessage &&
            password !== '' &&
            passwordConfirmation !== '' &&
            termsOfUseAreAccepted) {
            handleNextButton(true);
        }
        else {
            handleNextButton(false);
        }
    }, [
        password,
        passwordConfirmation,
        termsOfUseAreAccepted,
        errorPasswordsAreDifferentMessage,
        errorPasswordIsNotValidMessage,
        handleNextButton,
        showBoundary,
    ]);
    const passwordTextQuestion = {
        label: '¿Qué contraseña de acceso utilizarás?',
        placeholder: '',
    };
    const passwordConfirmationTextQuestion = {
        label: 'Confirma la contraseña',
        placeholder: '',
    };
    const termsOfUseModalContent = {
        title: (_b = (_a = collectivePersonalization === null || collectivePersonalization === void 0 ? void 0 : collectivePersonalization.termsOfUseModal) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : texts.modalsTexts.termsOfUseModal.title,
        description: (_d = (_c = collectivePersonalization === null || collectivePersonalization === void 0 ? void 0 : collectivePersonalization.termsOfUseModal) === null || _c === void 0 ? void 0 : _c.description) !== null && _d !== void 0 ? _d : texts.modalsTexts.termsOfUseModal.description,
        buttonText: (_f = (_e = collectivePersonalization === null || collectivePersonalization === void 0 ? void 0 : collectivePersonalization.termsOfUseModal) === null || _e === void 0 ? void 0 : _e.buttonText) !== null && _f !== void 0 ? _f : texts.modalsTexts.termsOfUseModal.buttonText,
    };
    const onChange = (value, name) => {
        if (name === 'password') {
            setPassword(value);
            saveResponse({
                name: 'password',
                value: value,
            });
        }
        else if (name === 'confirmationPassword') {
            setPasswordConfirmation(value);
        }
    };
    const onChangeTermsOfUseAreAcceptedCheckInput = (e) => {
        setTermsOfUseAreAccepted(e.target.checked);
    };
    const onChangeHasReadAndAcceptsCommunicationsCheckInput = (e) => {
        saveResponse({
            name: 'has_read_and_accepts_communications',
            value: e.target.checked,
        });
    };
    const getDescription = () => {
        var _a, _b;
        const description = (_b = (_a = collectivePersonalization === null || collectivePersonalization === void 0 ? void 0 : collectivePersonalization.loginInformationScreen) === null || _a === void 0 ? void 0 : _a.subDescription) !== null && _b !== void 0 ? _b : texts.registrationPageTexts.loginInformationScreen.subDescription.replace(/\[.*?\]/g, nif ? nif : '');
        return { __html: description };
    };
    const invalidPasswordErrorMessage = { __html: texts.registrationPageTexts.validationMessages.invalidPasswordFormat };
    return (React.createElement("div", { className: "registration-page__content" },
        React.createElement("h2", { className: "registration-page__content-subtitle", "data-testid": 'login-information-page-title' }, (_h = (_g = collectivePersonalization === null || collectivePersonalization === void 0 ? void 0 : collectivePersonalization.loginInformationScreen) === null || _g === void 0 ? void 0 : _g.subtitle) !== null && _h !== void 0 ? _h : texts.registrationPageTexts.loginInformationScreen.subtitle.toUpperCase()),
        React.createElement("div", { className: "registration-page__content-body" },
            React.createElement("div", { className: "registration-page__content-subdescription", "data-testid": "login-information-description", dangerouslySetInnerHTML: getDescription() }),
            React.createElement("div", { className: "registration-page__content-questions" },
                React.createElement("div", { className: "registration-page__content-question" },
                    React.createElement(RegistrationTextQuestion, { id: "password", content: passwordTextQuestion, onChange: onChange, fieldName: 'password', type: "password", error: errorPasswordsAreDifferentMessage }),
                    errorPasswordsAreDifferentMessage && (React.createElement("div", { id: "", className: "registration-page__content-error_message", "data-testid": "passwords-are-different" }, texts.registrationPageTexts.validationMessages.nonIdenticalPasswords)),
                    errorPasswordIsNotValidMessage && (React.createElement("div", { id: "", className: "registration-page__content-error_message", "data-testid": "password-error-message", dangerouslySetInnerHTML: invalidPasswordErrorMessage }))),
                React.createElement("div", { className: "registration-page__content-question" },
                    React.createElement(RegistrationTextQuestion, { id: "password_confirm", content: passwordConfirmationTextQuestion, onChange: onChange, fieldName: 'confirmationPassword', type: "password", error: errorPasswordsAreDifferentMessage }))),
            React.createElement("div", { className: "registration-page__content-separator" }),
            React.createElement("div", { className: "registration-page__content-form-check" },
                React.createElement("input", { className: "registration-page__content-form-check-input", type: "checkbox", value: "", id: "termsOfUseCheckbox", onChange: onChangeTermsOfUseAreAcceptedCheckInput }),
                React.createElement("div", { className: "registration-page__content-form-check-labels" },
                    React.createElement("label", { className: "registration-page__content-form-check-label", htmlFor: "termsOfUseCheckbox" }, (_k = (_j = collectivePersonalization === null || collectivePersonalization === void 0 ? void 0 : collectivePersonalization.loginInformationScreen) === null || _j === void 0 ? void 0 : _j.termsOfUseCheckbox) !== null && _k !== void 0 ? _k : texts.registrationPageTexts.loginInformationScreen.termsOfUseCheckbox),
                    React.createElement("div", { className: "registration-page__content-form-check-label-info" }, (_m = (_l = collectivePersonalization === null || collectivePersonalization === void 0 ? void 0 : collectivePersonalization.loginInformationScreen) === null || _l === void 0 ? void 0 : _l.termsOfUseAdditionalText) !== null && _m !== void 0 ? _m : texts.registrationPageTexts.loginInformationScreen.termsOfUseAdditionalText,
                        React.createElement(LegalInfoModal, { modalContent: termsOfUseModalContent, linkText: 'aquí' })))),
            React.createElement("div", { className: "registration-page__content-form-check" },
                React.createElement("input", { className: "registration-page__content-form-check-input", type: "checkbox", value: "", id: "hasReadAndAcceptsCommunicationsCheckbox", onChange: onChangeHasReadAndAcceptsCommunicationsCheckInput }),
                React.createElement("div", { className: "registration-page__content-form-check-labels" },
                    React.createElement("label", { className: "registration-page__content-form-check-label", htmlFor: "hasReadAndAcceptsCommunicationsCheckbox" }, (_p = (_o = collectivePersonalization === null || collectivePersonalization === void 0 ? void 0 : collectivePersonalization.loginInformationScreen) === null || _o === void 0 ? void 0 : _o.hasReadAndAcceptsCommunicationsText) !== null && _p !== void 0 ? _p : texts.registrationPageTexts.loginInformationScreen.hasReadAndAcceptsCommunicationsText))))));
};
export default LoginInformation;
