export const updateRegistrationResponse = (responses, response) => {
    const newResponses = [...responses];
    const existingResponse = newResponses.find((r) => r.name === response.name);
    response.value !== ''
        ? newResponses.splice(newResponses.indexOf(existingResponse), existingResponse ? 1 : 0, response)
        : newResponses.splice(newResponses.indexOf(existingResponse), 1);
    return newResponses;
};
export const updateRegistrationResponses = (responses, currentResponses) => {
    const newResponses = [...responses];
    currentResponses.forEach((currentResponse) => {
        const existingResponse = newResponses.find((r) => r.name === currentResponse.name);
        currentResponse.value !== ''
            ? newResponses.splice(newResponses.indexOf(existingResponse), existingResponse ? 1 : 0, currentResponse)
            : newResponses.splice(newResponses.indexOf(existingResponse), 1);
    });
    return newResponses;
};
