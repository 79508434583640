import React, { useContext } from 'react';
import { InfoContentSubtype } from '../../../../../types/FormModelTypes';
import InfoScreen from './InfoScreen';
import IntroAndEndScreen from './IntroAndEndScreen';
import OtIntroScreen from './OtIntroScreen';
import UserContext from '../../../../../contexts/UserContext';
const InfoContainer = ({ content, goToNextContent }) => {
    var _a;
    const { getPersonalization, getCheckPersonalizationApiCalled } = useContext(UserContext);
    const renderScreen = () => {
        switch (content.subtype) {
            case InfoContentSubtype.INTRO_AND_END:
                return React.createElement(IntroAndEndScreen, { content: content, goToNextContent: goToNextContent });
            case InfoContentSubtype.OT_INTRO:
                return React.createElement(OtIntroScreen, { content: content, goToNextContent: goToNextContent });
            default:
                return React.createElement(InfoScreen, { content: content, goToNextContent: goToNextContent });
        }
    };
    const getImageUrl = () => {
        const personalization = getPersonalization();
        if (!(personalization === null || personalization === void 0 ? void 0 : personalization.formImages)) {
            return null;
        }
        const formImage = personalization.formImages.find((formImage) => formImage.contentId === content.id);
        return formImage && formImage.urlImage;
    };
    return (React.createElement("div", { className: "info-container", "data-testid": "info-component" },
        content.subtype !== InfoContentSubtype.OT_INTRO && (React.createElement("div", { className: "card__image" }, getCheckPersonalizationApiCalled() && React.createElement("img", { alt: "Intro card image", src: (_a = getImageUrl()) !== null && _a !== void 0 ? _a : content.imageUrl }))),
        renderScreen()));
};
export default InfoContainer;
