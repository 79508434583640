import React from 'react';
import { ContentType } from '../../../../types/FormModelTypes';
import texts from '../../../../data/texts.json';
import { INFO_ICON_WHITE_URL } from '../../../../utils/constants';
const ContextualHelpButton = ({ modalType, contentType }) => {
    return (React.createElement("button", { type: "button", className: "contextual-help__button", "data-testid": "contextual-help-button", "data-bs-toggle": "offcanvas", "data-bs-target": `#${modalType}` },
        React.createElement("img", { className: "contextual-help__button-icon", alt: "Info icon", src: INFO_ICON_WHITE_URL }),
        React.createElement("a", { className: "contextual-help__button-text" }, contentType === ContentType.MULTIPLE
            ? texts.modalsTexts.contextualHelpModal.displayButtonInMultipleQuestion
            : texts.modalsTexts.contextualHelpModal.displayButtonInBinaryQuestion)));
};
export default ContextualHelpButton;
