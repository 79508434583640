import React, { useContext } from 'react';
import texts from '../../../../../data/texts.json';
import EipdContext from '../../../../../contexts/EipdContext';
const OtItem = ({ index, ot, goToComplete }) => {
    const { saveCurrentOt } = useContext(EipdContext);
    const goToCompleteOtInformation = () => {
        saveCurrentOt(ot);
        goToComplete();
    };
    return (React.createElement("div", { className: "card" },
        React.createElement("div", { className: "card-header" },
            React.createElement("div", { className: "card-header__text", "data-testid": `ot-card-${index}` }, ot.title),
            React.createElement("button", { className: "button--complete", "data-testid": `complete-button-${index}`, onClick: goToCompleteOtInformation },
                React.createElement("div", { className: "button--complete__text" }, texts.iterativeOtsTexts.complete.toUpperCase()),
                React.createElement("i", { className: "bi bi-arrow-right-circle" }))),
        React.createElement("div", { className: "card-body" },
            React.createElement("p", { className: "card-text" }, ot.purpose))));
};
export default OtItem;
