import React from 'react';
import texts from '../../../data/texts.json';
import { DocumentType } from '../../../types/DocumentationTypes';
const DocumentsSection = ({ documentsList }) => {
    const getSectionTitle = (documentsList) => {
        const adjustTitle = (title) => {
            return (documentsList === null || documentsList === void 0 ? void 0 : documentsList.list.length) <= 1 ? title.replace(/\bdocumentos\b/, 'documento').replace(/\bdisponibles\b/, 'disponible') : title;
        };
        return (documentsList === null || documentsList === void 0 ? void 0 : documentsList.type) === DocumentType.PDD
            ? adjustTitle(texts.documentationPageTexts.pddDocumentsSectionTitle)
            : adjustTitle(texts.documentationPageTexts.cpsDocumentsSectionTitle);
    };
    return (React.createElement("div", { "data-testid": `${(documentsList === null || documentsList === void 0 ? void 0 : documentsList.type) === DocumentType.PDD ? 'pdd' : 'cps'}-documents-list` },
        React.createElement("h3", { className: "documentation-page__body-section-title", "data-testid": "documents-section-title" }, documentsList === null || documentsList === void 0 ? void 0 :
            documentsList.list.length,
            " ",
            getSectionTitle(documentsList)),
        React.createElement("ul", { className: "documentation-page__body-documents-list" }, documentsList === null || documentsList === void 0 ? void 0 : documentsList.list.map((document) => (React.createElement("div", { className: "card", key: document.id },
            React.createElement("div", { className: "card-header" },
                React.createElement("div", { className: "card-header__text", "data-testid": `document-item-${document.id}` }, document.documentName),
                React.createElement("div", { className: "card-header__actions" },
                    documentsList.type === DocumentType.PDD && document.userGuideUrl && (React.createElement("a", { href: document.userGuideUrl, className: "button--download", "data-testid": `user-guide-button-${document.id}`, target: "_blank", rel: "noreferrer" },
                        React.createElement("p", { className: "button--download__text" }, texts.documentationPageTexts.seeUserGuideButton.toUpperCase()),
                        React.createElement("i", { className: "bi bi-info-circle" }))),
                    React.createElement("a", { href: document.fileUrl, className: "button--download", "data-testid": `download-button-${document.id}`, target: "_blank", rel: "noreferrer" },
                        React.createElement("p", { className: "button--download__text" }, texts.documentationPageTexts.seeDocumentButton.toUpperCase()),
                        React.createElement("i", { className: "bi bi-file-text" })))),
            documentsList.type === DocumentType.PDD && document.documentDescription && (React.createElement("div", { className: "card-body", "data-testid": `document-description-${document.id}` },
                React.createElement("p", { className: "card-text", dangerouslySetInnerHTML: { __html: document.documentDescription } })))))))));
};
export default DocumentsSection;
