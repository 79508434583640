var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getIsDpdMandatoryResult } from './signoAPI';
import { log } from '../utils/logger';
import { performAxiosRequest } from './requestHelper';
export const calculateIsDpdMandatory = (userFormId) => __awaiter(void 0, void 0, void 0, function* () {
    return performAxiosRequest(() => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield getIsDpdMandatoryResult(userFormId);
        log.debug('[DPD Service] GET is DPD mandatory - response.status ==> ', response.status);
        return response.status === 200 && response.data;
    }));
});
