import React from 'react';
import DisclaimerContainer from '../DisclaimerContainer';
import PrimaryButton from '../../common/Buttons/PrimaryButton';
import { Helmet } from 'react-helmet';
import texts from '../../../data/texts.json';
const ResetPasswordConfirmation = ({ goToLogin }) => {
    return (React.createElement(DisclaimerContainer, null,
        React.createElement(Helmet, null,
            React.createElement("title", null,
                "Signo | ",
                texts.resetPasswordPageTexts.passwordChangedText.pageTitle)),
        React.createElement("h1", { className: "disclaimer-page__title", "data-testid": "reset-password-page-title" }, texts.resetPasswordPageTexts.passwordChangedText.pageTitle),
        React.createElement("div", { className: "disclaimer-page__description", dangerouslySetInnerHTML: { __html: texts.resetPasswordPageTexts.passwordChangedText.message } }),
        React.createElement("div", { className: "disclaimer-page__next" },
            React.createElement(PrimaryButton, { text: texts.resetPasswordPageTexts.passwordChangedText.submitButton.toUpperCase(), onClickFn: goToLogin, testId: "login-btn" }))));
};
export default ResetPasswordConfirmation;
