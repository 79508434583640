import React, { useEffect, useState } from 'react';
import Select from 'react-select';
const Dropdown = ({ label, placeholder, questionValues, onChangeFn, onChangeMultipleFn, onInputChangeFn, fieldName, isMultiSelect, isOptionDisable, }) => {
    const [questionOptions, setQuestionOptions] = useState([]);
    useEffect(() => {
        setQuestionOptions(questionValues);
    }, [label, questionValues]);
    const options = questionOptions === null || questionOptions === void 0 ? void 0 : questionOptions.map((option) => {
        return { value: option.value, label: option.text };
    });
    const handleOnSelectSingle = (newValue) => {
        onChangeFn(newValue.value, fieldName ? fieldName : null);
    };
    const handleOnSelectMultiple = (newValues) => {
        onChangeMultipleFn(newValues.map((newValue) => newValue.value));
    };
    const handleOnInputChange = (inputValue) => {
        onInputChangeFn === null || onInputChangeFn === void 0 ? void 0 : onInputChangeFn(inputValue);
    };
    return (React.createElement("div", { className: "dropdown__container", "data-testid": "dropdown-container" },
        React.createElement("label", { className: "dropdown__label", "data-testid": "dropdown-label" }, label),
        React.createElement(Select, { options: options, onInputChange: handleOnInputChange, placeholder: placeholder, onChange: isMultiSelect ? handleOnSelectMultiple : handleOnSelectSingle, classNamePrefix: "dropdown__element", menuPlacement: "auto", menuShouldScrollIntoView: true, menuPortalTarget: document.body, isMulti: isMultiSelect, isOptionDisabled: () => isOptionDisable })));
};
export default Dropdown;
