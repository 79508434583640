import React, { useEffect, useState } from 'react';
import RegistrationBinaryQuestion from './Questions/RegistrationBinaryQuestion';
import { updateRegistrationResponse } from './Questions/RegistrationResponse';
import texts from '../../../../data/texts.json';
const LegalData = ({ setIsSelfEmployed, saveResponse, handleNextButton }) => {
    const [responses, setResponses] = useState([]);
    useEffect(() => {
        if ((responses === null || responses === void 0 ? void 0 : responses.length) == 1) {
            handleNextButton(true);
        }
        else {
            handleNextButton(false);
        }
    }, [responses, handleNextButton]);
    const isSelfEmployeeBinaryQuestion = {
        description: '¿Estás activando Signo para una empresa o para una persona trabajadora autónoma?',
        questionValues: [
            {
                value: '0',
                text: 'Empresa',
            },
            {
                value: '1',
                text: 'Persona trabajadora autónoma',
            },
        ],
    };
    const updateResponse = (response) => {
        const newResponses = updateRegistrationResponse(responses, response);
        setResponses(newResponses);
    };
    const onClick = (questionValue) => {
        const response = {
            name: 'self_employed',
            value: questionValue === '1',
        };
        setIsSelfEmployed(questionValue === '1');
        saveResponse(response);
        updateResponse(response);
    };
    return (React.createElement("div", { className: "registration-page__content" },
        React.createElement("h2", { className: "registration-page__content-subtitle", "data-testid": 'legal-data-page-title' }, texts.registrationPageTexts.legalDataScreen.subtitle.toUpperCase()),
        React.createElement("div", { className: "registration-page__content-body" },
            React.createElement("div", { className: "registration-page__content-question multi-content" },
                React.createElement(RegistrationBinaryQuestion, { content: isSelfEmployeeBinaryQuestion, onClick: onClick })))));
};
export default LegalData;
