var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MainContainer from '../MainContainer';
import SecondaryButton from '../../common/Buttons/SecondaryButton';
import PrimaryButton from '../../common/Buttons/PrimaryButton';
import Intro from './RegistrationSteps/Intro';
import LegalData from './RegistrationSteps/LegalData';
import LocationAndContact from './RegistrationSteps/LocationAndContact';
import BusinessActivity from './RegistrationSteps/BusinessActivity';
import Staff from './RegistrationSteps/Staff';
import LoginInformation from './RegistrationSteps/LoginInformation';
import LegalRepresentative from './RegistrationSteps/LegalRepresentative';
import PublicAdministrationDisclaimer from './OtherRegistrationScreens/PublicAdministrationDisclaimer';
import InitialRegistrationScreen from './OtherRegistrationScreens/InitialRegistrationScreen';
import LegalDataCompany from './RegistrationSteps/LegalDataCompany';
import LegalDataSelfEmployed from './RegistrationSteps/LegalDataSelfEmployed';
import ErrorPage, { ErrorEnum } from '../Error/ErrorPage';
import { submitUserRegistration } from '../../../api/userService';
import { fetchCollectiveId } from '../../../api/collectiveService';
import texts from '../../../data/texts.json';
import { useErrorBoundary } from 'react-error-boundary';
import { log } from '../../../utils/logger';
import { changeColorStyle, changeFontType } from '../../../utils/formSpecificsUtils';
var RegistrationSteps;
(function (RegistrationSteps) {
    RegistrationSteps[RegistrationSteps["Start"] = 0] = "Start";
    RegistrationSteps[RegistrationSteps["Intro"] = 1] = "Intro";
    RegistrationSteps[RegistrationSteps["PublicAdmin"] = 2] = "PublicAdmin";
    RegistrationSteps[RegistrationSteps["LegalData"] = 3] = "LegalData";
    RegistrationSteps[RegistrationSteps["LegalDataCompany"] = 4] = "LegalDataCompany";
    RegistrationSteps[RegistrationSteps["LegalDataSelfEmployed"] = 5] = "LegalDataSelfEmployed";
    RegistrationSteps[RegistrationSteps["LegalRepresentative"] = 6] = "LegalRepresentative";
    RegistrationSteps[RegistrationSteps["LocationAndContact"] = 7] = "LocationAndContact";
    RegistrationSteps[RegistrationSteps["BusinessActivity"] = 8] = "BusinessActivity";
    RegistrationSteps[RegistrationSteps["Staff"] = 9] = "Staff";
    RegistrationSteps[RegistrationSteps["LoginInformation"] = 10] = "LoginInformation";
})(RegistrationSteps || (RegistrationSteps = {}));
const Registration = () => {
    const [isPublicAdmin, setIsPublicAdmin] = useState(false);
    const [isSelfEmployed, setIsSelfEmployed] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
    const [responses, setResponses] = useState([]);
    const [registrationError, setRegistrationError] = useState(null);
    const [collectiveId, setCollectiveId] = useState(null);
    const [collectivePersonalization, setCollectivePersonalization] = useState(null);
    const [isCollectiveApiResponded, setIsCollectiveApiResponded] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const collectiveHash = searchParams.get('c');
    const { showBoundary } = useErrorBoundary();
    const [isOscIntegrationInProgress, setIsOscIntegrationInProgress] = useState(false);
    useEffect(() => {
        if (collectiveId === null && registrationError === null) {
            fetchCollectiveId(collectiveHash)
                .then((response) => {
                var _a, _b;
                if (response === null) {
                    setRegistrationError(ErrorEnum.REGISTRATION_INVALID_COLLECTIVE);
                }
                else {
                    setCollectiveId(response.id);
                    setCollectivePersonalization(response === null || response === void 0 ? void 0 : response.personalization);
                    if ((_a = response === null || response === void 0 ? void 0 : response.personalization) === null || _a === void 0 ? void 0 : _a.colors) {
                        changeColorStyle(response.personalization.colors);
                    }
                    if ((_b = response.personalization) === null || _b === void 0 ? void 0 : _b.fonts) {
                        changeFontType(response.personalization.fonts);
                    }
                }
                setIsCollectiveApiResponded(true);
            })
                .catch((e) => showBoundary(e));
        }
    }, [collectiveHash, collectiveId, navigate, registrationError, showBoundary]);
    const saveResponse = (response) => {
        const newResponses = [...responses];
        const existingResponse = newResponses.find((r) => r.name === response.name);
        response.value !== ''
            ? newResponses.splice(newResponses.indexOf(existingResponse), existingResponse ? 1 : 0, response)
            : newResponses.splice(newResponses.indexOf(existingResponse), 1);
        setResponses(newResponses);
    };
    const saveResponses = (newResponses) => {
        setResponses((prevResponses) => {
            const filteredResponses = newResponses.filter((newResponse) => {
                return !prevResponses.some((prevResponse) => prevResponse.name === newResponse.name);
            });
            const updatedResponses = [...prevResponses, ...filteredResponses];
            return updatedResponses;
        });
    };
    const getResponses = () => {
        return responses;
    };
    const goBack = () => {
        if (!isPublicAdmin) {
            switch (currentStep) {
                case RegistrationSteps.Intro:
                    setCurrentStep(RegistrationSteps.Start);
                    break;
                case RegistrationSteps.LegalData:
                    setCurrentStep(RegistrationSteps.Intro);
                    break;
                case RegistrationSteps.LegalDataCompany:
                    setCurrentStep(RegistrationSteps.LegalData);
                    break;
                case RegistrationSteps.LegalDataSelfEmployed:
                    setCurrentStep(RegistrationSteps.LegalData);
                    break;
                case RegistrationSteps.LegalRepresentative:
                    setCurrentStep(RegistrationSteps.LegalDataCompany);
                    break;
                case RegistrationSteps.LocationAndContact:
                    isSelfEmployed ? setCurrentStep(RegistrationSteps.LegalDataSelfEmployed) : setCurrentStep(RegistrationSteps.LegalRepresentative);
                    break;
                case RegistrationSteps.BusinessActivity:
                    setCurrentStep(RegistrationSteps.LocationAndContact);
                    break;
                case RegistrationSteps.Staff:
                    setCurrentStep(RegistrationSteps.BusinessActivity);
                    break;
                case RegistrationSteps.LoginInformation:
                    setCurrentStep(RegistrationSteps.Staff);
                    break;
            }
        }
        else if (isPublicAdmin && currentStep === RegistrationSteps.Intro) {
            setIsPublicAdmin(false);
            setCurrentStep(RegistrationSteps.Start);
        }
    };
    const goToNextStep = () => {
        if (isPublicAdmin) {
            switch (currentStep) {
                case RegistrationSteps.Intro:
                    setCurrentStep(RegistrationSteps.PublicAdmin);
                    setIsNextButtonDisabled(true);
                    break;
                case RegistrationSteps.PublicAdmin:
                    setCurrentStep(RegistrationSteps.Intro);
                    setIsNextButtonDisabled(true);
                    break;
            }
        }
        else {
            switch (currentStep) {
                case RegistrationSteps.Start:
                    setCurrentStep(RegistrationSteps.Intro);
                    break;
                case RegistrationSteps.Intro:
                    setCurrentStep(RegistrationSteps.LegalData);
                    break;
                case RegistrationSteps.LegalData:
                    isSelfEmployed ? setCurrentStep(RegistrationSteps.LegalDataSelfEmployed) : setCurrentStep(RegistrationSteps.LegalDataCompany);
                    break;
                case RegistrationSteps.LegalDataCompany:
                    setCurrentStep(RegistrationSteps.LegalRepresentative);
                    break;
                case RegistrationSteps.LegalDataSelfEmployed:
                    setCurrentStep(RegistrationSteps.LocationAndContact);
                    break;
                case RegistrationSteps.LegalRepresentative:
                    setCurrentStep(RegistrationSteps.LocationAndContact);
                    break;
                case RegistrationSteps.LocationAndContact:
                    setCurrentStep(RegistrationSteps.BusinessActivity);
                    break;
                case RegistrationSteps.BusinessActivity:
                    setCurrentStep(RegistrationSteps.Staff);
                    break;
                case RegistrationSteps.Staff:
                    setCurrentStep(RegistrationSteps.LoginInformation);
                    break;
            }
            setIsNextButtonDisabled(true);
        }
    };
    const handleNextButton = (enable) => {
        setIsNextButtonDisabled(!enable);
    };
    const getResponseFieldValue = (field) => {
        var _a;
        return (_a = responses.find((r) => r.name == field)) === null || _a === void 0 ? void 0 : _a.value;
    };
    const signUp = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsOscIntegrationInProgress(true);
        const userRegistrationData = {
            business_name: !isSelfEmployed ? getResponseFieldValue('business_name').toString() : null,
            document_id: !isSelfEmployed ? getResponseFieldValue('document_id').toString() : null,
            address: getResponseFieldValue('address').toString(),
            zipcode: getResponseFieldValue('zipcode').toString(),
            city: getResponseFieldValue('city').toString(),
            province: getResponseFieldValue('province').toString(),
            phone_number: getResponseFieldValue('phone_number').toString(),
            email_address: getResponseFieldValue('email_address').toString(),
            activity_id: Number(getResponseFieldValue('activity_id')),
            employees: Number(getResponseFieldValue('employees').toString()),
            password: getResponseFieldValue('password').toString(),
            self_employed: Boolean(getResponseFieldValue('self_employed').toString() == 'true'),
            rlegal_name: getResponseFieldValue('rlegal_name').toString(),
            rlegal_first_surname: getResponseFieldValue('rlegal_first_surname').toString(),
            rlegal_last_surname: getResponseFieldValue('rlegal_last_surname') ? getResponseFieldValue('rlegal_last_surname').toString() : '',
            rlegal_document_id: getResponseFieldValue('rlegal_document_id').toString(),
            rlegal_role: !isSelfEmployed ? getResponseFieldValue('rlegal_role').toString() : null,
            collective_id: collectiveId,
            has_read_and_accepts_communications: getResponseFieldValue('has_read_and_accepts_communications')
                ? Boolean(getResponseFieldValue('has_read_and_accepts_communications'))
                : false,
        };
        try {
            const successfullyRegistered = yield submitUserRegistration(userRegistrationData);
            setIsOscIntegrationInProgress(false);
            if (successfullyRegistered) {
                log.debug('IN REGISTRATION COMPONENT - is successfullyRegistered ===> ', successfullyRegistered);
                navigate('/login', { state: { successMessage: texts.registrationPageTexts.alertMessages.successfulRegistration } });
            }
            else {
                log.error('IN REGISTRATION COMPONENT - is NOT successfullyRegistered ===> ', successfullyRegistered);
                setRegistrationError(ErrorEnum.REGISTRATION_ERROR);
            }
        }
        catch (e) {
            setIsOscIntegrationInProgress(false);
            log.error('IN REGISTRATION COMPONENT - error ===> ', e);
            showBoundary(e);
        }
    });
    const getNif = () => {
        var _a, _b;
        return isSelfEmployed
            ? (_a = responses.find((r) => r.name == 'rlegal_document_id')) === null || _a === void 0 ? void 0 : _a.value.toString()
            : (_b = responses.find((r) => r.name == 'document_id')) === null || _b === void 0 ? void 0 : _b.value.toString();
    };
    const renderRegistrationScreenTitle = () => {
        if (currentStep === RegistrationSteps.Intro) {
            return React.createElement("h1", { className: "registration-page__title" }, texts.registrationPageTexts.introScreen.title);
        }
        else {
            return React.createElement("h1", { className: "registration-page__title" }, texts.registrationPageTexts.defaultTitle);
        }
    };
    const renderRegistrationScreenBody = () => {
        switch (currentStep) {
            case RegistrationSteps.Intro:
                return React.createElement(Intro, { setIsPublicAdmin: setIsPublicAdmin, handleNextButton: handleNextButton });
            case RegistrationSteps.LegalData:
                return React.createElement(LegalData, { setIsSelfEmployed: setIsSelfEmployed, saveResponse: saveResponse, handleNextButton: handleNextButton });
            case RegistrationSteps.LegalDataCompany:
                return React.createElement(LegalDataCompany, { saveResponse: saveResponse, handleNextButton: handleNextButton });
            case RegistrationSteps.LegalDataSelfEmployed:
                return React.createElement(LegalDataSelfEmployed, { saveResponse: saveResponse, handleNextButton: handleNextButton });
            case RegistrationSteps.LocationAndContact:
                return (React.createElement(LocationAndContact, { saveResponse: saveResponse, saveResponses: saveResponses, getResponses: getResponses, handleNextButton: handleNextButton }));
            case RegistrationSteps.BusinessActivity:
                return React.createElement(BusinessActivity, { saveResponse: saveResponse, handleNextButton: handleNextButton });
            case RegistrationSteps.Staff:
                return React.createElement(Staff, { saveResponse: saveResponse, handleNextButton: handleNextButton });
            case RegistrationSteps.LegalRepresentative:
                return React.createElement(LegalRepresentative, { saveResponse: saveResponse, handleNextButton: handleNextButton });
            case RegistrationSteps.LoginInformation:
                return React.createElement(LoginInformation, { saveResponse: saveResponse, handleNextButton: handleNextButton, nif: getNif(), collectivePersonalization: collectivePersonalization });
        }
    };
    const renderRegistrationScreenButtons = () => {
        return (React.createElement("div", { className: "registration-page__next-buttons" },
            React.createElement(SecondaryButton, { text: "Atr\u00E1s", onClickFn: goBack, testId: "back-button", enabled: true, submitButton: false }),
            React.createElement(PrimaryButton, { text: isOscIntegrationInProgress
                    ? texts.registrationPageTexts.buttons.processing.toUpperCase()
                    : currentStep == RegistrationSteps.LoginInformation
                        ? texts.registrationPageTexts.buttons.submit.toUpperCase()
                        : texts.registrationPageTexts.buttons.goNext.toUpperCase(), onClickFn: currentStep == RegistrationSteps.LoginInformation ? signUp : goToNextStep, testId: "next-button", isDisabled: isNextButtonDisabled || isOscIntegrationInProgress, size: "M" })));
    };
    return registrationError !== null ? (React.createElement(ErrorPage, { error: registrationError })) : currentStep === RegistrationSteps.Start ? (React.createElement(InitialRegistrationScreen, { collectivePersonalization: collectivePersonalization, goToNextStep: goToNextStep, isCollectiveApiResponded: isCollectiveApiResponded })) : currentStep !== RegistrationSteps.PublicAdmin ? (React.createElement(MainContainer, { collectivePersonalization: collectivePersonalization, isCollectiveApiResponded: isCollectiveApiResponded },
        React.createElement("div", { className: "main-content registration-page" },
            renderRegistrationScreenTitle(),
            renderRegistrationScreenBody(),
            renderRegistrationScreenButtons()))) : (React.createElement(PublicAdministrationDisclaimer, { collectivePersonalization: collectivePersonalization, goBack: goToNextStep }));
};
export default Registration;
