import React, { useState } from 'react';
export const NavigationButton = ({ onClickFn, isDisabled, isBackButton }) => {
    const [isHovered, setIsHovered] = useState(false);
    const handleHover = () => {
        !isDisabled && setIsHovered(!isHovered);
    };
    return (React.createElement("button", { className: 'button--back', onClick: onClickFn, disabled: isDisabled, onMouseEnter: handleHover, onMouseLeave: handleHover },
        React.createElement("i", { className: `bi ${!isDisabled && isHovered
                ? isBackButton
                    ? 'bi-arrow-left-square-fill'
                    : 'bi-arrow-right-square-fill'
                : isBackButton
                    ? 'bi-arrow-left-square'
                    : 'bi-arrow-right-square'}`, title: isBackButton ? 'Atrás' : 'Siguiente' })));
};
