import React, { useContext, useEffect, useState } from 'react';
import InfoContainer from './InfoContent/InfoContainer';
import MultiContentContainer from './MultiContent/MultiContentContainer';
import SingleContentContainer from './SingleContent/SingleContentContainer';
import IterativeResume from './IterativeContent/IterativeResume';
import IterativeOts from './IterativeContent/IterativeOts';
import { ContentType } from '../../../../types/FormModelTypes';
import MultiContentOTs from './MultiContent/MultiContentOTs';
import { NavigationButton } from '../../../common/Buttons/NavigationButton';
import FormResponseContext from '../../../../contexts/FormResponseContext';
import texts from '../../../../data/texts.json';
const ContentContainer = ({ currentContent, goToNextContent, navToBackContent, navToNextContent }) => {
    const [navigationIsVisible, setNavigationIsVisible] = useState(false);
    const [navBackButtonIsDisabled, setNavBackButtonIsDisabled] = useState(false);
    const [navNextButtonIsDisabled, setNavNextButtonIsDisabled] = useState(false);
    const { getFormResponses } = useContext(FormResponseContext);
    const alertContent = {
        actionButton: texts.modalsTexts.updateResponseAlert.actionButton,
        title: texts.modalsTexts.updateResponseAlert.title,
        body: texts.modalsTexts.updateResponseAlert.body,
        closeButton: texts.modalsTexts.updateResponseAlert.closeButton,
        subBody: texts.modalsTexts.updateResponseAlert.subBody,
    };
    useEffect(() => {
        //TODO: Optimize this code
        const checkIfNavigationIsVisible = () => {
            if (currentContent.id === 'formStart-c1' ||
                currentContent.id.startsWith('s2-c4') ||
                currentContent.id.startsWith('s2-c5') ||
                (currentContent.id.startsWith('s6') && currentContent.id != 's6-c1') ||
                currentContent.id.startsWith('formEnd')) {
                setNavigationIsVisible(false);
            }
            else {
                setNavigationIsVisible(true);
            }
        };
        const checkIfBackButtonIsDisabled = () => {
            if (currentContent.id === 's1-c1') {
                setNavBackButtonIsDisabled(true);
            }
            else {
                setNavBackButtonIsDisabled(false);
            }
        };
        const checkIfNextButtonIsDisabled = () => {
            const formResponse = getFormResponses();
            setNavNextButtonIsDisabled(!formResponse.some((fr) => fr.contentId === currentContent.id) ||
                formResponse[formResponse.length - 1] == formResponse.find((fr) => fr.contentId === currentContent.id));
        };
        checkIfNavigationIsVisible();
        checkIfBackButtonIsDisabled();
        checkIfNextButtonIsDisabled();
    }, [currentContent, getFormResponses]);
    const renderComponent = () => {
        switch (currentContent === null || currentContent === void 0 ? void 0 : currentContent.type) {
            case ContentType.INFO:
                return React.createElement(InfoContainer, { content: currentContent, goToNextContent: goToNextContent });
            case ContentType.ITERATIVE_RESUME:
                return React.createElement(IterativeResume, { content: currentContent, goToNextContent: goToNextContent });
            case ContentType.ITERATIVE_OTS:
                return React.createElement(IterativeOts, { content: currentContent, goToNextContent: goToNextContent });
            case ContentType.MULTI_CONTENT:
                return React.createElement(MultiContentContainer, { content: currentContent, goToNextContent: goToNextContent });
            case ContentType.MULTI_CONTENT_OTS:
                return React.createElement(MultiContentOTs, { content: currentContent, goToNextContent: goToNextContent });
            case ContentType.BINARY:
            case ContentType.MULTIPLE:
            case ContentType.TEXT:
                return React.createElement(SingleContentContainer, { content: currentContent, goToNextContent: goToNextContent, alertContent: alertContent });
            default:
        }
    };
    const renderNavigation = () => {
        {
            return (navigationIsVisible && (React.createElement("div", { className: "navigation" },
                React.createElement(NavigationButton, { isDisabled: navBackButtonIsDisabled, isBackButton: true, onClickFn: navToBackContent, key: 'navBackButton' }),
                React.createElement(NavigationButton, { isDisabled: navNextButtonIsDisabled, isBackButton: false, onClickFn: navToNextContent, key: 'navNextButton' }))));
        }
    };
    return (React.createElement("div", { className: "main-content", "data-testid": "content" },
        renderComponent(),
        renderNavigation()));
};
export default ContentContainer;
