import React, { useEffect, useState } from 'react';
import { InputIcon } from '../../../types/FormModelTypes';
const Input = ({ id, placeholder, label, type, onChangeFn, error, icon, classname, fieldName, enabled, isEmpty, direction = '', maxLength, value = '', }) => {
    const [inputValue, setInputValue] = useState(value);
    useEffect(() => {
        if (isEmpty != undefined && isEmpty == true) {
            setInputValue('');
        }
    }, [isEmpty]);
    const handleOnChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
        onChangeFn(value, fieldName ? fieldName : null);
    };
    return (React.createElement("div", { className: `text-input__container ${direction}`, "data-testid": "text-type-question" },
        React.createElement("label", { htmlFor: "text-input", className: `form-label text-input__label ${classname} ${error && 'is-invalid'}`, "data-testid": "text-type-question-label" }, label),
        React.createElement("div", { className: "input-group" },
            icon && (React.createElement("div", { className: "input-group-prepend" },
                React.createElement("span", { className: "input-group-text text-input__icon" },
                    React.createElement("i", { "data-testid": "icon", className: ` ${icon === InputIcon.LINK ? 'bi bi-link' : icon === InputIcon.ENVELOPE ? 'bi bi-envelope-open' : ''}` })))),
            React.createElement("input", { type: type, "data-testid": "text-type-question-input", id: id || 'text-input', className: `form-control text-input__element ${error && 'is-invalid'} ${icon && 'text-input--without-left-border'}`, placeholder: placeholder, role: type, "aria-describedby": "", onChange: handleOnChange, disabled: enabled != undefined ? !enabled : false, value: inputValue, maxLength: maxLength }))));
};
export default Input;
