var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import MultipleQuestionButton from '../../../../../common/Buttons/MultipleQuestionButton';
import ContextualHelpModal from '../../../../../common/Modals/ContextualHelp/ContextualHelpModal';
const MultipleQuestion = ({ content, handleOnClick }) => {
    const [selectedResponses, setSelectedResponses] = useState();
    useEffect(() => {
        var _a;
        setSelectedResponses((_a = content === null || content === void 0 ? void 0 : content.formResponse) === null || _a === void 0 ? void 0 : _a.ocs_variables);
    }, [content]);
    const renderTextOutsideBracketsWithUppercase = (text) => {
        const indexOfFirstBracket = text.indexOf('(');
        return indexOfFirstBracket !== -1
            ? `${text.substring(0, indexOfFirstBracket).toUpperCase()}${text.substring(indexOfFirstBracket)}`
            : text.toUpperCase();
    };
    const getResponse = (q) => {
        return {
            contentId: content.id,
            valueType: content.type,
            ocs_entity: q.ocs_entity,
            ocs_variable: q.ocs_variable,
        };
    };
    const isSelected = (ocs_variable) => {
        return !!(selectedResponses === null || selectedResponses === void 0 ? void 0 : selectedResponses.find((s) => s === ocs_variable));
    };
    const onClick = (response) => __awaiter(void 0, void 0, void 0, function* () {
        let newOcsVariables = [response.ocs_variable];
        const existingResponse = selectedResponses === null || selectedResponses === void 0 ? void 0 : selectedResponses.find((r) => r === response.ocs_variable);
        if (existingResponse) {
            newOcsVariables = selectedResponses === null || selectedResponses === void 0 ? void 0 : selectedResponses.filter((r) => r !== response.ocs_variable);
        }
        else if (selectedResponses) {
            newOcsVariables = [...selectedResponses, response.ocs_variable];
        }
        const newResponse = response;
        newResponse.ocs_variable = null;
        newResponse.ocs_variables = newOcsVariables;
        setSelectedResponses(newOcsVariables);
        handleOnClick(newResponse);
    });
    return (React.createElement("div", { className: "question__answers-multiple", "data-testid": "multiple-type-question" },
        content.infoModal && React.createElement(ContextualHelpModal, { modalContent: content.infoModal, contentType: content.type }),
        content.questionValues.map((q) => {
            return (React.createElement(MultipleQuestionButton, { onClickFn: () => onClick(getResponse(q)), text: renderTextOutsideBracketsWithUppercase(q.text), key: q.value, selected: isSelected(q.ocs_variable) }));
        })));
};
export default MultipleQuestion;
