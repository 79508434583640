import { logger } from 'react-native-logs';
const defaultConfig = {
    levels: {
        trace: 0,
        debug: 1,
        info: 2,
        warn: 3,
        error: 4,
    },
    severity: 'trace',
    async: true,
    dateFormat: 'time',
    printLevel: true,
    printDate: false,
    enabled: window._env_.REACT_APP_ENABLE_CONSOLE_LOGGING && window._env_.REACT_APP_ENABLE_CONSOLE_LOGGING === 'true',
};
const log = logger.createLogger(defaultConfig);
log.trace('Console log debug message');
export { log };
