import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { getCompanyIdFromToken } from '../api/authHelper';
import { log } from '../utils/logger';
const AuthContext = createContext(null);
const AuthProvider = ({ children }) => {
    const [token, setToken_] = useState(localStorage.getItem('token'));
    const setToken = (newToken) => {
        log.debug('auth set token', newToken);
        setToken_(newToken);
    };
    useEffect(() => {
        if (token) {
            log.debug('auth effect');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            localStorage.setItem('token', token);
        }
        else {
            delete axios.defaults.headers.common['Authorization'];
            localStorage.removeItem('token');
        }
    }, [token]);
    const contextValue = useMemo(() => ({
        token,
        setToken,
        getCompanyId: () => {
            return getCompanyIdFromToken(token);
        },
    }), [token]);
    // Provide the authentication context to the children components
    return React.createElement(AuthContext.Provider, { value: contextValue }, children);
};
export const useAuth = () => {
    return useContext(AuthContext);
};
export default AuthProvider;
