import React, { createContext, useContext, useMemo, useState } from 'react';
const defaultMaintenanceContextValue = {
    isDeploying: false
};
const MaintenanceContext = createContext(defaultMaintenanceContextValue);
const MaintenanceContextProvider = ({ children }) => {
    const [isDeploying] = useState(window._env_.REACT_APP_IS_DEPLOYING && window._env_.REACT_APP_IS_DEPLOYING === 'true');
    const contextValue = useMemo(() => ({
        isDeploying,
    }), [isDeploying]);
    return React.createElement(MaintenanceContext.Provider, { value: contextValue }, children);
};
export const useMaintenance = () => {
    return useContext(MaintenanceContext);
};
export default MaintenanceContextProvider;
