import React from 'react';
import RegistrationBinaryQuestion from './Questions/RegistrationBinaryQuestion';
import texts from '../../../../data/texts.json';
import ContextualHelpModal from '../../../common/Modals/ContextualHelp/ContextualHelpModal';
import { ContentType } from '../../../../types/FormModelTypes';
const Intro = ({ setIsPublicAdmin, handleNextButton }) => {
    const isPublicAdminBinaryQuestion = {
        description: '¿Tu entidad es considerada una entidad pública?',
        questionValues: [
            {
                value: '0',
                text: 'No',
            },
            {
                value: '1',
                text: 'Sí',
            },
        ],
    };
    const publicEntityInfo = {
        title: texts.registrationPageTexts.introScreen.infoModal.title,
        description: texts.registrationPageTexts.introScreen.infoModal.description,
        buttonText: texts.registrationPageTexts.introScreen.infoModal.buttonText,
    };
    const onClick = (questionValue) => {
        setIsPublicAdmin(questionValue === '1');
        handleNextButton(true);
    };
    return (React.createElement("div", { className: "registration-page__content" },
        React.createElement("div", { className: "registration-page__content-body" },
            React.createElement("p", { className: "registration-page__content-description" }, texts.registrationPageTexts.introScreen.description),
            React.createElement("div", { className: "registration-page__content-question multi-content" },
                React.createElement(RegistrationBinaryQuestion, { content: isPublicAdminBinaryQuestion, onClick: onClick }),
                React.createElement(ContextualHelpModal, { modalContent: publicEntityInfo, contentType: ContentType.BINARY })))));
};
export default Intro;
