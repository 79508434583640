import React, { useEffect, useState } from 'react';
import DropdownQuestion from './DropdownQuestion';
import { provincesValues } from '../../../../../utils/provinces';
const DropdownProvinceQuestion = ({ content, handleOnChange }) => {
    const [questionValues, setQuestionValues] = useState([]);
    useEffect(() => {
        setQuestionValues(provincesValues);
    }, []);
    return React.createElement(DropdownQuestion, { content: Object.assign({}, content, { questionValues: questionValues }), handleOnChange: handleOnChange });
};
export default DropdownProvinceQuestion;
