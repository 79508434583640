import React from 'react';
import texts from '../../../../../../data/texts.json';
import MultipleQuestionButton from '../../../../../common/Buttons/MultipleQuestionButton';
const MultiContentMultipleQuestion = ({ content, selectedValue, handleOnClick }) => {
    const getResponse = (q) => {
        return {
            contentId: content.id,
            valueType: content.type,
            ocs_entity: q.ocs_entity,
            ocs_variable: q.ocs_variable,
        };
    };
    function isSelected(q) {
        return selectedValue && selectedValue.ocs_variables.includes(q.ocs_variable);
    }
    return (React.createElement("div", { className: "question__answers-multiple" },
        React.createElement("label", { className: "question__answers-multiple-label" },
            content.label,
            React.createElement("br", null),
            React.createElement("span", { className: "sublabel" }, texts.iterativeResumeTexts.multiSelectSubLabel)),
        React.createElement("div", { className: "question__answers-multiple-buttons", "data-testid": "multiple-type-question" }, content.questionValues.map((q) => {
            return (React.createElement(MultipleQuestionButton, { onClickFn: () => handleOnClick(getResponse(q)), text: q.text.toUpperCase(), key: q.value, selected: isSelected(q) }));
        }))));
};
export default MultiContentMultipleQuestion;
