export var ContentType;
(function (ContentType) {
    ContentType["TEXT"] = "text";
    ContentType["MULTIPLE"] = "multiple";
    ContentType["BINARY"] = "binary";
    ContentType["DROPDOWN"] = "dropdown";
    ContentType["INFO"] = "info";
    ContentType["MULTI_CONTENT"] = "multiContent";
    ContentType["MULTI_CONTENT_OTS"] = "multiContentOts";
    ContentType["ITERATIVE_RESUME"] = "iterativeResume";
    ContentType["ITERATIVE_OTS"] = "iterativeOts";
    ContentType["SWITCH"] = "switch";
})(ContentType || (ContentType = {}));
export var InfoContentSubtype;
(function (InfoContentSubtype) {
    InfoContentSubtype["INTRO_AND_END"] = "introAndEnd";
    InfoContentSubtype["OT_INTRO"] = "otIntro";
})(InfoContentSubtype || (InfoContentSubtype = {}));
export var DropdownContentSubtype;
(function (DropdownContentSubtype) {
    DropdownContentSubtype["ACTIVOS"] = "activos";
    DropdownContentSubtype["PROVINCE"] = "province";
})(DropdownContentSubtype || (DropdownContentSubtype = {}));
export var InputIcon;
(function (InputIcon) {
    InputIcon["LINK"] = "link";
    InputIcon["ENVELOPE"] = "envelope-open";
})(InputIcon || (InputIcon = {}));
export var ButtonIcon;
(function (ButtonIcon) {
    ButtonIcon["SPINNER"] = "spinner";
    ButtonIcon["ARROW"] = "arrow";
})(ButtonIcon || (ButtonIcon = {}));
