import { LOGO_URL } from '../../utils/constants';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import texts from '../../data/texts.json';
import { useAuth } from '../../contexts/AuthContext';
const MainContainer = ({ children, collectivePersonalization, isCollectiveApiResponded }) => {
    const [registrationSuccessMessage, setRegistrationSuccessMessage] = useState(false);
    const { state } = useLocation();
    const { token, setToken } = useAuth();
    const [logoUrl, setLogoUrl] = useState(null);
    useEffect(() => {
        if (state === null || state === void 0 ? void 0 : state.successMessage) {
            setRegistrationSuccessMessage(true);
            history.replaceState(null, '');
        }
    }, [state]);
    useEffect(() => {
        if (isCollectiveApiResponded) {
            if (collectivePersonalization) {
                if (collectivePersonalization.logoUrl) {
                    setLogoUrl(collectivePersonalization.logoUrl);
                }
                else {
                    setLogoUrl(LOGO_URL);
                }
            }
            else {
                setLogoUrl(LOGO_URL);
            }
        }
    }, [collectivePersonalization, isCollectiveApiResponded]);
    const handleLogout = () => {
        setToken(null);
    };
    return (React.createElement("div", { className: "global-container" },
        registrationSuccessMessage && (React.createElement("div", { className: "registration-message toast-container position-absolute", id: "toastPlacement" },
            React.createElement("div", { className: "toast show", role: "alert", "aria-live": "assertive", "aria-atomic": "true" },
                React.createElement("div", { className: "toast-header" },
                    React.createElement("strong", { className: "me-auto" }, "\u00A1Perfecto!"),
                    React.createElement("button", { type: "button", className: "btn-close", "data-bs-dismiss": "toast", "aria-label": "Close" })),
                React.createElement("div", { className: "toast-body" }, state === null || state === void 0 ? void 0 : state.successMessage)))),
        React.createElement("div", { className: "left-sidebar" },
            React.createElement("div", { className: "left-sidebar__logo" }, isCollectiveApiResponded && React.createElement("img", { alt: "Logo de SIGNO", src: logoUrl })),
            token && (React.createElement("div", { className: "left-sidebar__actions" },
                React.createElement("a", { className: "left-sidebar__actions-logout", onClick: handleLogout, href: "" }, texts.menuActionsTexts.logout)))),
        React.createElement("div", { className: "mobile-header" }, isCollectiveApiResponded && React.createElement("img", { alt: "Logo de SIGNO", src: logoUrl })),
        children));
};
export default MainContainer;
