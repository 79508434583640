import React from 'react';
import VerticalLine, { VerticalLinePositionEnum } from './VerticalLine';
const SubsectionItem = ({ subsection, currentSectionId }) => {
    const isActive = subsection.id === currentSectionId;
    return (React.createElement("div", { "data-testid": 'subsection', className: 'subsection-item' },
        React.createElement(VerticalLine, { transparent: false, position: VerticalLinePositionEnum.MIDDLE }),
        React.createElement("p", { className: `subsection-item__text ${isActive ? 'subsection-item__text--active' : ''}
        ${subsection.isCompleted ? 'subsection-item__text--completed' : ''}` }, subsection.sectionTitle)));
};
export default SubsectionItem;
