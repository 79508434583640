var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { log } from '../../../utils/logger';
import { Helmet } from 'react-helmet';
import texts from '../../../data/texts.json';
import Input from '../../common/Inputs/Input';
import SecondaryButton from '../../common/Buttons/SecondaryButton';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { doPasswordValidation, submitResetPassword } from '../../../api/userService';
import ResetPasswordConfirmation from './ResetPasswordConfirmation';
import MainContainer from '../MainContainer';
import ErrorPage, { ErrorEnum } from '../Error/ErrorPage';
import { useErrorBoundary } from 'react-error-boundary';
const ResetPassword = () => {
    const [userName, setUserName] = useState(null);
    const [password, setPassword] = useState(null);
    const [passwordConfirmation, setPasswordConfirmation] = useState(null);
    const [handleNextButton, setHandleNextButton] = useState(false);
    const [errorPasswordIsNotValidMessage, setErrorPasswordIsNotValidMessage] = useState(false);
    const [errorPasswordsAreDifferentMessage, setErrorPasswordsAreDifferentMessage] = useState(false);
    const [errorTokenIsNotValid, setErrorTokenIsNotValid] = useState(false);
    const [passwordHasBeenChanged, setPasswordHasBeenChanged] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get('c');
    const { showBoundary } = useErrorBoundary();
    useEffect(() => {
        log.debug('ResetPassword useEffect...');
        if (!token) {
            setErrorTokenIsNotValid(true);
            return;
        }
        const validatePassword = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const isPasswordValid = yield doPasswordValidation(password);
                setErrorPasswordIsNotValidMessage(!isPasswordValid);
            }
            catch (e) {
                showBoundary(e);
            }
        });
        if (passwordConfirmation && password && passwordConfirmation !== password) {
            setErrorPasswordsAreDifferentMessage(true);
        }
        else {
            setErrorPasswordsAreDifferentMessage(false);
        }
        if (!password) {
            setErrorPasswordIsNotValidMessage(false);
        }
        else {
            validatePassword().then(() => log.debug('Validate password call finished'));
        }
        if (!errorPasswordsAreDifferentMessage && !errorPasswordIsNotValidMessage && userName && password && passwordConfirmation) {
            setHandleNextButton(true);
        }
        else {
            setHandleNextButton(false);
        }
    }, [errorPasswordIsNotValidMessage, errorPasswordsAreDifferentMessage, password, passwordConfirmation, token, userName, showBoundary]);
    const handleUserIdChange = (value) => {
        log.debug('UserName', value);
        setUserName(value);
    };
    const handlePasswordChange = (value) => {
        log.debug('Password', value);
        setPassword(value);
    };
    const handlePasswordConfirmationChange = (value) => {
        log.debug('PasswordConfirmation', value);
        setPasswordConfirmation(value);
    };
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        const hasResetPassword = yield submitResetPassword({
            username: userName,
            password: password,
            token: encodeURIComponent(token),
        });
        hasResetPassword ? setPasswordHasBeenChanged(true) : setErrorTokenIsNotValid(true);
    });
    const goToLogin = () => __awaiter(void 0, void 0, void 0, function* () {
        navigate('/login');
    });
    const renderResetPasswordForm = () => {
        return (React.createElement(MainContainer, null,
            React.createElement(Helmet, null,
                React.createElement("title", null,
                    "Signo | ",
                    texts.resetPasswordPageTexts.formPageText.pageTitle)),
            React.createElement("div", { className: "main-content reset-password-page" },
                React.createElement("h1", { className: "reset-password-page__title", "data-testid": "reset-password-page-title" }, texts.resetPasswordPageTexts.formPageText.pageTitle),
                React.createElement("div", { className: "reset-password-page__body" },
                    React.createElement("div", { className: "reset-password-page__body__inputs" },
                        React.createElement("div", { className: "reset-password-page__body__inputs-username", "data-testid": "input-block" },
                            React.createElement(Input, { id: 'documentId', label: texts.resetPasswordPageTexts.formPageText.documentIdLabel, placeholder: texts.resetPasswordPageTexts.formPageText.documentIdPlaceholder, type: "text", onChangeFn: handleUserIdChange, "data-testid": 'document-id-input-test-id' })),
                        React.createElement("div", { className: "reset-password-page__body__inputs-password", "data-testid": "input-block" },
                            React.createElement(Input, { id: 'password', label: texts.resetPasswordPageTexts.formPageText.passwordLabel, placeholder: texts.resetPasswordPageTexts.formPageText.passwordPlaceholder, type: "password", onChangeFn: handlePasswordChange, "data-testid": 'password-field-input-test-id', error: errorPasswordIsNotValidMessage }),
                            React.createElement(Input, { id: 'password-confirmation', label: texts.resetPasswordPageTexts.formPageText.passwordConfirmationLabel, placeholder: texts.resetPasswordPageTexts.formPageText.passwordConfirmationPlaceholder, type: "password", onChangeFn: handlePasswordConfirmationChange, "data-testid": 'password-confirmation-field', error: errorPasswordsAreDifferentMessage }))),
                    React.createElement("div", { className: "reset-password-page__body__error-messages" },
                        errorPasswordIsNotValidMessage && (React.createElement("div", { id: "", className: "registration-page__content-error_message", role: "alert", "data-testid": "reset-password-password-not-valid-error", dangerouslySetInnerHTML: { __html: texts.resetPasswordPageTexts.formPageText.invalidPasswordFormat } })),
                        errorPasswordsAreDifferentMessage && (React.createElement("div", { id: "", className: "registration-page__content-error_message", role: "alert", "data-testid": "reset-password-confirmation-not-valid-error" }, texts.resetPasswordPageTexts.formPageText.nonIdenticalPasswords)))),
                React.createElement("div", { className: "reset-password-page__next-buttons" },
                    React.createElement(SecondaryButton, { text: texts.resetPasswordPageTexts.formPageText.cancelButton, onClickFn: goToLogin, testId: "cancel-btn", enabled: true, submitButton: true }),
                    React.createElement(SecondaryButton, { text: texts.resetPasswordPageTexts.formPageText.submitButton, onClickFn: handleSubmit, testId: "submit-btn", enabled: handleNextButton, submitButton: true })))));
    };
    return errorTokenIsNotValid ? (React.createElement(ErrorPage, { error: ErrorEnum.PASSWORD_RESET })) : passwordHasBeenChanged ? (React.createElement(ResetPasswordConfirmation, { goToLogin: goToLogin })) : (renderResetPasswordForm());
};
export default ResetPassword;
