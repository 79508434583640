import React, { useEffect, useState } from 'react';
import RegistrationTextQuestion from './Questions/RegistrationTextQuestion';
import { updateRegistrationResponse } from './Questions/RegistrationResponse';
import { isNaturalPersonNumberValid } from '../../../../api/signoAPI';
import texts from '../../../../data/texts.json';
const LegalRepresentative = ({ saveResponse, handleNextButton }) => {
    const [responses, setResponses] = useState([]);
    const [documentIdIsInvalid, setdocumentIdIsInvalid] = useState(false);
    useEffect(() => {
        const secondSurname = responses.find((r) => r.name == 'rlegal_last_surname');
        if (((responses === null || responses === void 0 ? void 0 : responses.length) == 5 || ((responses === null || responses === void 0 ? void 0 : responses.length) == 4 && !secondSurname)) && !documentIdIsInvalid) {
            handleNextButton(true);
        }
        else {
            handleNextButton(false);
        }
    }, [responses, handleNextButton, documentIdIsInvalid]);
    const legalRepresentativeNameTextQuestion = {
        label: '¿Cuál es su nombre?',
        placeholder: '',
    };
    const legalRepresentativeFirstSurnameTextQuestion = {
        label: '¿Cuál es su primer apellido?',
        placeholder: '',
    };
    const legalRepresentativeSecondSurnameTextQuestion = {
        label: '¿Y su segundo apellido?',
        placeholder: '',
    };
    const legalRepresentativeDocumentIdTextQuestion = {
        label: '¿Y cuál es su DNI o NIE?',
        placeholder: '0000000000',
    };
    const legalRepresentativeRoleTextQuestion = {
        label: '¿Y su cargo?',
        placeholder: '',
    };
    const updateResponse = (response) => {
        const newResponses = updateRegistrationResponse(responses, response);
        setResponses(newResponses);
    };
    const checkIfDocumentIdIsValid = (value) => {
        isNaturalPersonNumberValid(value)
            .then((res) => {
            if (res.status === 200) {
                setdocumentIdIsInvalid(false);
            }
            else {
                setdocumentIdIsInvalid(true);
            }
        })
            .catch(() => {
            setdocumentIdIsInvalid(true);
        });
    };
    const onChange = (value, name) => {
        if (name === 'rlegal_document_id') {
            checkIfDocumentIdIsValid(value);
        }
        const response = {
            name: name,
            value: value,
        };
        saveResponse(response);
        updateResponse(response);
    };
    return (React.createElement("div", { className: "registration-page__content" },
        React.createElement("h2", { className: "registration-page__content-subtitle", "data-testid": 'legal-representative-page-title' }, texts.registrationPageTexts.legalRepresentativeScreen.subtitle.toUpperCase()),
        React.createElement("div", { className: "registration-page__content-body" },
            React.createElement("div", { className: "registration-page__content-fullname" },
                React.createElement("div", { className: "registration-page__content-question registration-page__content-firstname" },
                    React.createElement(RegistrationTextQuestion, { id: 'rlegal_name', content: legalRepresentativeNameTextQuestion, onChange: onChange, fieldName: 'rlegal_name', maxLength: 50 })),
                React.createElement("div", { className: "registration-page__content-question registration-page__content-first-lastname" },
                    React.createElement(RegistrationTextQuestion, { id: 'rlegal_first_surname', content: legalRepresentativeFirstSurnameTextQuestion, onChange: onChange, fieldName: 'rlegal_first_surname', maxLength: 49 })),
                React.createElement("div", { className: "registration-page__content-question registration-page__content-second-lastname" },
                    React.createElement(RegistrationTextQuestion, { id: 'rlegal_last_surname', content: legalRepresentativeSecondSurnameTextQuestion, onChange: onChange, fieldName: 'rlegal_last_surname', maxLength: 49 }))),
            React.createElement("div", { className: "registration-page__content-question" },
                React.createElement(RegistrationTextQuestion, { id: 'rlegal_document_id', content: legalRepresentativeDocumentIdTextQuestion, onChange: onChange, fieldName: 'rlegal_document_id' }),
                documentIdIsInvalid && (React.createElement("div", { id: "", className: "registration-page__content-error_message", "data-testid": "document-id-is-invalid" }, texts.registrationPageTexts.validationMessages.invalidIdentityNumber))),
            React.createElement("div", { className: "registration-page__content-question" },
                React.createElement(RegistrationTextQuestion, { id: 'rlegal_role', content: legalRepresentativeRoleTextQuestion, onChange: onChange, fieldName: 'rlegal_role', maxLength: 50 })))));
};
export default LegalRepresentative;
