import React from 'react';
import { Helmet } from 'react-helmet';
import AuthProvider from './contexts/AuthContext';
import { UserContextProvider } from './contexts/UserContext';
import Routes from './routes/Routes';
import MaintenanceContextProvider from './contexts/MaintenanceContext';
function App() {
    return (React.createElement("div", { className: "App" },
        React.createElement(Helmet, null,
            React.createElement("title", null, "Signo | Cumplimiento normativo")),
        React.createElement(MaintenanceContextProvider, null,
            React.createElement(AuthProvider, null,
                React.createElement(UserContextProvider, null,
                    React.createElement(Routes, null))))));
}
export default App;
