var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getUserFormInfo, getUserFormResponses, postNewUserForm, postUserForm, putFormResponseHistory, putUserFormResponses } from './signoAPI';
import { performAxiosRequest } from './requestHelper';
import { log } from '../utils/logger';
export const createUserForm = (companyId, serviceId = '1') => __awaiter(void 0, void 0, void 0, function* () {
    return performAxiosRequest(() => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield postNewUserForm(companyId, serviceId);
        log.debug('[Form service] POST new form - response.status ==> ', response.status);
        return response.status === 201 && response.data;
    }), [404], null);
});
export const fetchUserFormInfo = (companyId, serviceId = '1') => __awaiter(void 0, void 0, void 0, function* () {
    return performAxiosRequest(() => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield getUserFormInfo(companyId, serviceId);
        log.debug('[Form service] GET existing form info - response.status ==> ', response.status);
        return response.status === 200 && response.data;
    }), [404], false);
});
export const fetchUserFormResponses = (userFormId) => __awaiter(void 0, void 0, void 0, function* () {
    return performAxiosRequest(() => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield getUserFormResponses(userFormId);
        log.debug('[Form service] GET form responses - response.status ==> ', response.status);
        return response.status === 200 && response.data;
    }));
});
export const sendUserFormResponses = (responses, userFormId) => __awaiter(void 0, void 0, void 0, function* () {
    return performAxiosRequest(() => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield putUserFormResponses(responses, userFormId);
        log.debug('[Form service] PUT form responses - response.status ==> ', response.status);
        return response.status === 200 || response.status === 204;
    }));
});
export const sendFormResponseHistory = (formResponseHistoryRequest) => __awaiter(void 0, void 0, void 0, function* () {
    return performAxiosRequest(() => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield putFormResponseHistory(formResponseHistoryRequest);
        log.debug('[Form service] PUT form response history - response.status ==> ', response.status);
        return response.status === 200;
    }));
});
export const submitUserForm = (responses, userFormId) => __awaiter(void 0, void 0, void 0, function* () {
    return performAxiosRequest(() => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield postUserForm(responses, userFormId);
        log.debug('[Form service] POST all form responses - response.status ==> ', response.status);
        return response.status === 200 || response.status === 201;
    }));
});
