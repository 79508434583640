var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getCompanyInfo, postUserLogin, postUserRegistration, postUserResetPassword, postValidatePassword } from './signoAPI';
import { log } from '../utils/logger';
import { performAxiosRequest } from './requestHelper';
export const submitUserLogin = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return performAxiosRequest(() => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield postUserLogin(body);
        log.debug('[User service] GET login - response.status ==> ', response.status);
        return response.status === 200 && response.data;
    }), [400, 401], false);
});
export const submitUserRegistration = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return performAxiosRequest(() => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield postUserRegistration(body);
        log.debug('[User service] POST registration - response.status ===> ', response.status);
        return response.status === 200;
    }), [400, 403], false);
});
export const submitResetPassword = (body) => __awaiter(void 0, void 0, void 0, function* () {
    return performAxiosRequest(() => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield postUserResetPassword(body);
        log.debug('[User service] POST reset password - response.status ===> ', response.status);
        return response.status === 200;
    }), [400, 403], false);
});
export const doPasswordValidation = (password) => __awaiter(void 0, void 0, void 0, function* () {
    return performAxiosRequest(() => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield postValidatePassword(password);
        log.debug('[User service] POST validate password - response.status ===> ', response.status);
        return response.status === 200;
    }), [400, 403, 406], false);
});
export const fetchCompanyInfo = (companyId) => __awaiter(void 0, void 0, void 0, function* () {
    return performAxiosRequest(() => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield getCompanyInfo(companyId);
        log.debug('[User service] GET company info - response.status ===> ', response.status);
        return response.status === 200 && response.data;
    }));
});
