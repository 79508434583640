var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import AlertDialog from './AlertDialog';
import PrimaryButton from '../Buttons/PrimaryButton';
import { createUserForm } from '../../../api/formService';
const InfoModal = ({ modalContent, modalType, showResetLink, alertContent }) => {
    const [showAlert, setShowAlert] = useState(false);
    const modalDescription = { __html: modalContent === null || modalContent === void 0 ? void 0 : modalContent.description };
    const { getCompanyId } = useAuth();
    const openAlert = () => {
        setShowAlert(true);
    };
    const closeAlert = () => {
        setShowAlert(false);
    };
    const resetForm = () => __awaiter(void 0, void 0, void 0, function* () {
        if (getCompanyId() !== undefined) {
            const newForm = yield createUserForm(getCompanyId());
            if (newForm.id) {
                setShowAlert(false);
                window.location.reload();
            }
        }
    });
    return (React.createElement("div", { className: "offcanvas offcanvas-start", id: modalType, "aria-labelledby": modalType, "data-testid": "info-modal" },
        React.createElement("div", { className: "info-modal__container" },
            React.createElement("div", { className: "info-modal__content" },
                React.createElement("div", { className: "info-modal__close-btn", "data-bs-dismiss": "offcanvas", "aria-label": "Close", "data-testid": "modal-close-button" },
                    React.createElement("i", { className: "bi bi-x info-modal__close-btn-icon" })),
                React.createElement("div", { className: "offcanvas-body info-modal__body" },
                    React.createElement("h1", { className: "info-modal__body__title", "data-testid": "modal-title" }, modalContent === null || modalContent === void 0 ? void 0 : modalContent.title),
                    React.createElement("div", { className: "info-modal__body__description", "data-testid": "modal-description" },
                        React.createElement("div", { className: "info-modal__body__description-main", dangerouslySetInnerHTML: modalDescription }),
                        showResetLink && (React.createElement("p", null, modalContent === null || modalContent === void 0 ? void 0 :
                            modalContent.resetForm.text,
                            ' ',
                            React.createElement("a", { onClick: openAlert, href: "#" }, modalContent === null || modalContent === void 0 ? void 0 : modalContent.resetForm.link))),
                        showAlert && React.createElement(AlertDialog, { alertContent: alertContent, closeAlertDialog: closeAlert, handleAlertActionFn: resetForm }),
                        (modalContent === null || modalContent === void 0 ? void 0 : modalContent.additionalText) && (React.createElement("p", { className: "info-modal__body__additional-text", "data-testid": "modal-additional-text", dangerouslySetInnerHTML: { __html: modalContent === null || modalContent === void 0 ? void 0 : modalContent.additionalText } }))))),
            React.createElement("div", { className: "info-modal__button" },
                React.createElement(PrimaryButton, { testId: 'modal-button', text: modalContent === null || modalContent === void 0 ? void 0 : modalContent.buttonText, onClickFn: () => {
                        document.getElementById(modalType).setAttribute('data-bs-dismiss', 'offcanvas');
                    } })))));
};
export default InfoModal;
