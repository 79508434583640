import React from 'react';
const MultiContentBinaryQuestion = ({ handleOnClick, content, selectedValue }) => {
    const getResponse = (q) => {
        return {
            contentId: content.id,
            contentValue: q.value,
            valueType: content.type,
            ocs_entity: content.ocs_entity,
            ocs_variable: content.ocs_variable,
        };
    };
    function isSelected(q) {
        return (selectedValue && selectedValue.contentValue === q.value);
    }
    return (React.createElement("div", { className: "question__answers-binary", "data-testid": "binary-type-question" },
        React.createElement("label", { className: "question__answers-binary-label" }, content.label),
        React.createElement("div", { className: "question__answers-binary-buttons" }, content.questionValues.map((q) => {
            return (React.createElement("button", { "data-testid": "binary-button", key: q.order, onClick: () => handleOnClick(getResponse(q)), className: `button--multi-content-binary ${isSelected(q) ? 'button--multi-content-binary--selected' : ''}` }, q.text.toUpperCase()));
        }))));
};
export default MultiContentBinaryQuestion;
