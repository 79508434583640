import React, { useEffect, useState } from 'react';
import RegistrationTextQuestion from './Questions/RegistrationTextQuestion';
import { updateRegistrationResponse, updateRegistrationResponses } from './Questions/RegistrationResponse';
import texts from '../../../../data/texts.json';
import Dropdown from '../../../common/Selectors/Dropdown';
import { getLocationsByPostalCode } from '../../../../api/signoAPI';
const LocationAndContact = ({ saveResponse, saveResponses, getResponses, handleNextButton }) => {
    const [responses, setResponses] = useState([]);
    const [emailIsInvalidErrorMessage, setEmailIsInvalidErrorMessage] = useState(false);
    const [phoneNumberIsInvalidErrorMessage, setPhoneNumberIsInvalidErrorMessage] = useState(false);
    const [locations, setLocations] = useState([]);
    useEffect(() => {
        const validateEmail = () => {
            var _a;
            const emailRegex = '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$';
            const email = (_a = responses.find((r) => r.name == 'email_address')) === null || _a === void 0 ? void 0 : _a.value.toString();
            if (!email || email.match(emailRegex)) {
                setEmailIsInvalidErrorMessage(false);
            }
            else {
                setEmailIsInvalidErrorMessage(true);
            }
        };
        const validatePhoneNumber = () => {
            var _a;
            const phoneRegex = '^[0-9]{9}$';
            const phone = (_a = responses.find((r) => r.name == 'phone_number')) === null || _a === void 0 ? void 0 : _a.value.toString();
            if (!phone || phone.match(phoneRegex)) {
                setPhoneNumberIsInvalidErrorMessage(false);
            }
            else {
                setPhoneNumberIsInvalidErrorMessage(true);
            }
        };
        validateEmail();
        validatePhoneNumber();
        if (phoneNumberIsInvalidErrorMessage) {
            const errorElement = document.getElementById('phone-is-invalid');
            errorElement.scrollIntoView();
        }
        else if (emailIsInvalidErrorMessage) {
            const errorElement = document.getElementById('email-is-invalid');
            errorElement.scrollIntoView();
        }
        if ((responses === null || responses === void 0 ? void 0 : responses.length) == 6 && !emailIsInvalidErrorMessage && !phoneNumberIsInvalidErrorMessage) {
            handleNextButton(true);
        }
        else {
            handleNextButton(false);
        }
    }, [responses, handleNextButton, emailIsInvalidErrorMessage, phoneNumberIsInvalidErrorMessage]);
    const businessAddressTextQuestion = {
        label: '¿Cuál es la dirección de la empresa?',
        placeholder: 'Indica calle y número, así como otra información necesaria',
    };
    const businessZipCodeDropdownQuestion = {
        label: '¿Qué código postal?',
        placeholder: 'Introduce tu código postal para buscar tu ciudad',
        values: locations,
    };
    const businessPhoneNumberTextQuestion = {
        label: 'Indícanos un teléfono de contacto',
        placeholder: '000000000',
    };
    const businessEmailTextQuestion = {
        label: '¿Qué email queréis utilizar como contacto?',
        placeholder: 'direccion@email.es',
    };
    const onChangeDropdown = (inputValue) => {
        const location = locations.find((l) => l.value === inputValue);
        if (location) {
            const zipCodeResponse = { name: 'zipcode', value: inputValue.split('-')[0].trim() };
            const provinceResponse = { name: 'province', value: location.text.split(',')[2].trim() };
            const cityResponse = { name: 'city', value: location.text.split(',')[0].trim() };
            const globalResponses = getResponses();
            [zipCodeResponse, provinceResponse, cityResponse].forEach((newResponse) => {
                const existingIndex = globalResponses.findIndex((r) => r.name === newResponse.name);
                if (existingIndex !== -1) {
                    globalResponses[existingIndex].value = newResponse.value;
                }
                else {
                    globalResponses.push(newResponse);
                }
                const localIndex = responses.findIndex((r) => r.name === newResponse.name);
                if (localIndex !== -1) {
                    responses[localIndex].value = newResponse.value;
                }
                else {
                    responses.push(newResponse);
                }
            });
            saveResponses(responses);
            updateResponses(responses);
        }
    };
    const onChange = (value, name) => {
        const response = {
            name: name,
            value: value,
        };
        saveResponse(response);
        updateResponse(response);
    };
    const onInputDropdownChange = (value) => {
        const zipcodeRegex = '^[0-9]{5}$';
        const zipcode = value;
        if (zipcode.match(zipcodeRegex)) {
            getLocationsByPostalCode(value)
                .then((response) => {
                if (response.data) {
                    setLocations(response.data);
                }
                else {
                    setLocations([]);
                }
            })
                .catch(console.debug);
        }
    };
    const updateResponse = (response) => {
        const newResponses = updateRegistrationResponse(responses, response);
        setResponses(newResponses);
    };
    const updateResponses = (responsesUser) => {
        const newResponses = updateRegistrationResponses(responsesUser, responses);
        setResponses(newResponses);
    };
    return (React.createElement("div", { className: "registration-page__content" },
        React.createElement("h2", { className: "registration-page__content-subtitle", "data-testid": 'location-and-contact-page-title' }, texts.registrationPageTexts.locationAndContactScreen.subtitle.toUpperCase()),
        React.createElement("div", { className: "registration-page__content-body" },
            React.createElement("div", { className: "registration-page__content-question" },
                React.createElement(RegistrationTextQuestion, { id: 'address', content: businessAddressTextQuestion, onChange: onChange, fieldName: 'address', maxLength: 150 })),
            React.createElement("div", { className: "registration-page__content-question registration-page__content-address-zipcode" },
                React.createElement(Dropdown, { label: businessZipCodeDropdownQuestion.label, placeholder: businessZipCodeDropdownQuestion.placeholder, questionValues: businessZipCodeDropdownQuestion.values, isMultiSelect: false, onInputChangeFn: onInputDropdownChange, onChangeFn: onChangeDropdown, fieldName: 'zipcode' })),
            React.createElement("div", { className: "registration-page__content-question" },
                React.createElement(RegistrationTextQuestion, { id: 'phone_number', content: businessPhoneNumberTextQuestion, onChange: onChange, fieldName: 'phone_number', error: phoneNumberIsInvalidErrorMessage }),
                phoneNumberIsInvalidErrorMessage && (React.createElement("div", { id: "phone-is-invalid", className: "registration-page__content-error_message", "data-testid": "phone-number-is-invalid" }, texts.registrationPageTexts.validationMessages.invalidPhoneNumber))),
            React.createElement("div", { className: "registration-page__content-question" },
                React.createElement(RegistrationTextQuestion, { id: 'email_address', content: businessEmailTextQuestion, onChange: onChange, fieldName: 'email_address', error: emailIsInvalidErrorMessage, maxLength: 75 }),
                emailIsInvalidErrorMessage && (React.createElement("div", { id: "email-is-invalid", className: "registration-page__content-error_message", "data-testid": "email-is-invalid" }, texts.registrationPageTexts.validationMessages.invalidEmailAddress))))));
};
export default LocationAndContact;
