import React, { useContext } from 'react';
import Input from '../../../../common/Inputs/Input';
import ContextualHelpModal from '../../../../common/Modals/ContextualHelp/ContextualHelpModal';
import FormResponseContext from '../../../../../contexts/FormResponseContext';
const TextQuestion = ({ content, handleOnChange }) => {
    const { getFormResponses } = useContext(FormResponseContext);
    const onChangeFn = (value) => {
        const response = {
            contentId: content.id,
            contentValue: value,
            valueType: content.type,
            ocs_entity: content.ocs_entity,
            ocs_variable: content.ocs_variable,
        };
        handleOnChange(response);
    };
    const getValueResponded = () => {
        var _a;
        const contentValue = (_a = getFormResponses().find((fr) => fr.contentId === content.id)) === null || _a === void 0 ? void 0 : _a.contentValue;
        return contentValue;
    };
    return (React.createElement(React.Fragment, null,
        content.infoModal && React.createElement(ContextualHelpModal, { modalContent: content.infoModal, contentType: content.type }),
        React.createElement(Input, { label: content.label, placeholder: content.placeholder, type: "text", onChangeFn: onChangeFn, icon: content.icon, value: getValueResponded() })));
};
export default TextQuestion;
