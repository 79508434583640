import React, { useState } from 'react';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';
const AlertDialog = ({ alertContent, closeAlertDialog, handleAlertActionFn }) => {
    const [isActionBtnDisabled, setIsActionBtnDisabled] = useState(false);
    const handleClick = () => {
        setIsActionBtnDisabled(true);
        handleAlertActionFn();
    };
    return (React.createElement("div", { className: "modal fade show", style: { display: 'block' }, id: "alert-dialog" },
        React.createElement("div", { className: "modal-dialog modal-dialog-centered" },
            React.createElement("div", { className: "modal-content" },
                React.createElement("div", { className: "modal-header" },
                    React.createElement("h4", { className: "modal-title" }, alertContent.title),
                    React.createElement("button", { type: "button", className: "btn-close", onClick: closeAlertDialog, "aria-label": "Close" })),
                React.createElement("div", { className: "modal-body" },
                    React.createElement("p", null, alertContent.body),
                    React.createElement("p", null, alertContent === null || alertContent === void 0 ? void 0 : alertContent.subBody)),
                React.createElement("div", { className: "modal-footer" },
                    React.createElement(SecondaryButton, { testId: 'modal-button', text: alertContent === null || alertContent === void 0 ? void 0 : alertContent.closeButton, onClickFn: closeAlertDialog, enabled: true, submitButton: false }),
                    React.createElement(PrimaryButton, { testId: 'modal-button', text: alertContent === null || alertContent === void 0 ? void 0 : alertContent.actionButton.toUpperCase(), onClickFn: handleClick, isDisabled: isActionBtnDisabled }))))));
};
export default AlertDialog;
