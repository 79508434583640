import React, { useState } from 'react';
const RegistrationBinaryQuestion = ({ content, onClick }) => {
    const [selectedResponse, setSelectedResponse] = useState('');
    const isSelected = (question) => {
        return question && question.value === selectedResponse;
    };
    const handleOnClick = (question) => {
        setSelectedResponse(question.value);
        onClick(question.value);
    };
    return (React.createElement("div", { className: "question__answers-binary", "data-testid": "binary-type-question" },
        React.createElement("label", { className: "question__answers-binary-label registration-page__content-binary-question-label", "data-testid": "question-label" }, content.description),
        React.createElement("div", { className: "question__answers-binary-buttons" }, content.questionValues.map((question, index) => {
            return (React.createElement("button", { "data-testid": `binary-button-${question.text}`, key: index, onClick: () => handleOnClick(question), className: `button--multi-content-binary registration-page__binary-question-button ${isSelected(question) ? 'button--multi-content-binary--selected' : ''}` }, question.text.toUpperCase()));
        }))));
};
export default RegistrationBinaryQuestion;
