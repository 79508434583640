import React from 'react';
import { ButtonIcon } from '../../../types/FormModelTypes';
const PrimaryButton = ({ text, onClickFn, testId, icon, size, isDisabled }) => {
    return (React.createElement("button", { "data-testid": testId, className: `primary-btn ${size === 'M' && 'primary-btn--size-m'} ${size === 'XL' && 'primary-btn--size-xl'} ${icon && icon === ButtonIcon.SPINNER && 'primary-btn--with-spinner'}`, onClick: onClickFn, disabled: isDisabled },
        icon && icon === ButtonIcon.SPINNER && isDisabled && (React.createElement("span", { className: "spinner" },
            React.createElement("div", { className: "spinner-border text-light", role: "status" },
                React.createElement("span", { className: "visually-hidden" }, "Loading...")))),
        React.createElement("span", { className: `${icon && icon === ButtonIcon.SPINNER && isDisabled && 'primary-btn--text-with-spinner'}` }, text)));
};
export default PrimaryButton;
