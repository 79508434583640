import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
export const ProtectedRoute = () => {
    const { token } = useAuth();
    if (!token) {
        return React.createElement(Navigate, { to: "/login" });
    }
    return React.createElement(Outlet, null);
};
