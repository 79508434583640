var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { performAxiosRequest } from './requestHelper';
import { getIsDocumentationGenerated } from './signoAPI';
import { log } from '../utils/logger';
export const isDocumentationGenerated = (userFormId) => __awaiter(void 0, void 0, void 0, function* () {
    return performAxiosRequest(() => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield getIsDocumentationGenerated(userFormId);
        log.debug('[Documentation Service] GET is documentation generated - response.status ==> ', response.data.id);
        return response.status === 200 && response.data;
    }), [404], null);
});
