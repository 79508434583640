import React, { useContext } from 'react';
import NextButton from '../../../../common/Buttons/NextButton';
import EipdContext from '../../../../../contexts/EipdContext';
const OtIntroScreen = ({ content, goToNextContent }) => {
    const { getCurrentOt, saveCurrentOt } = useContext(EipdContext);
    const getTitle = () => {
        return getCurrentOt() !== undefined ? getCurrentOt().title : content.title;
    };
    const getDescription = () => {
        return getCurrentOt() !== undefined ? getCurrentOt().purpose : content.description;
    };
    const handleCancel = () => {
        saveCurrentOt(null);
        goToNextContent(content.cancelButton.linkTo);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "ot-intro" },
            React.createElement("div", { className: "ot-intro__header" },
                React.createElement("h1", { className: "ot-intro__header-title", "data-testid": "content-title" }, getTitle()),
                React.createElement("p", { className: "ot-intro__header-description", "data-testid": "content-description" }, getDescription()))),
        React.createElement("div", { className: "multi-content__next-action" },
            content.cancelButton && (React.createElement("button", { onClick: handleCancel, className: "button--cancel", "data-testid": "cancel-button" }, content.cancelButton.title.toUpperCase())),
            React.createElement(NextButton, { onClickFn: () => goToNextContent(content.nextButton.linkTo), enabled: true, text: content.nextButton.title.toUpperCase() }))));
};
export default OtIntroScreen;
