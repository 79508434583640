import React, { useEffect, useState } from 'react';
import texts from '../../../../data/texts.json';
import RegistrationTextQuestion from './Questions/RegistrationTextQuestion';
import { checkUsernameExists, isNaturalPersonNumberValid } from '../../../../api/signoAPI';
import { updateRegistrationResponse } from './Questions/RegistrationResponse';
const LegalDataSelfEmployed = ({ saveResponse, handleNextButton }) => {
    const [responses, setResponses] = useState([]);
    const [nifIsInvalid, setNifIsInvalid] = useState(false);
    const [isNifDuplicated, setIsNifDuplicated] = useState(false);
    useEffect(() => {
        if (nifIsInvalid) {
            const errorElement = document.getElementById('nif-is-invalid');
            errorElement === null || errorElement === void 0 ? void 0 : errorElement.scrollIntoView();
        }
        else if (isNifDuplicated) {
            const errorElement = document.getElementById('nif-is-duplicated');
            errorElement === null || errorElement === void 0 ? void 0 : errorElement.scrollIntoView();
        }
        const secondSurname = responses.find((r) => r.name == 'rlegal_last_surname');
        if (((responses === null || responses === void 0 ? void 0 : responses.length) == 4 || ((responses === null || responses === void 0 ? void 0 : responses.length) == 3 && !secondSurname)) && !nifIsInvalid && !isNifDuplicated) {
            handleNextButton(true);
        }
        else {
            handleNextButton(false);
        }
    }, [responses, handleNextButton, nifIsInvalid, isNifDuplicated]);
    const firstNameTextQuestion = {
        label: '¿Cuál es su nombre?',
        placeholder: '',
    };
    const firstSurnameTextQuestion = {
        label: '¿Cuál es su primer apellido?',
        placeholder: '',
    };
    const secondSurnameTextQuestion = {
        label: '¿Y su segundo apellido?',
        placeholder: '',
    };
    const documentIdTextQuestion = {
        label: '¿Y cuál es el NIF?',
        placeholder: '0000000000',
    };
    const updateResponse = (response) => {
        const newResponses = updateRegistrationResponse(responses, response);
        setResponses(newResponses);
    };
    const checkIfDocumentIdIsValid = (value) => {
        setIsNifDuplicated(false);
        isNaturalPersonNumberValid(value)
            .then((res) => {
            if (res.status === 200) {
                setNifIsInvalid(false);
                checkUsernameExists({ username: value })
                    .then(({ status }) => {
                    switch (status) {
                        case 200:
                            setIsNifDuplicated(true);
                            break;
                        case 404:
                            setIsNifDuplicated(false);
                            break;
                        default:
                            break;
                    }
                })
                    .catch(() => setIsNifDuplicated(false));
            }
            else {
                setNifIsInvalid(true);
            }
        })
            .catch(() => {
            setNifIsInvalid(true);
        });
    };
    const onChange = (value, name) => {
        if (name === 'rlegal_document_id') {
            checkIfDocumentIdIsValid(value);
        }
        const response = {
            name: name,
            value: value,
        };
        saveResponse(response);
        updateResponse(response);
    };
    return (React.createElement("div", { className: "registration-page__content" },
        React.createElement("h2", { className: "registration-page__content-subtitle", "data-testid": 'legal-data-self-employed-page-title' }, texts.registrationPageTexts.legalDataSelfEmployedScreen.subtitle.toUpperCase()),
        React.createElement("div", { className: "registration-page__content-body" },
            React.createElement("div", { className: "registration-page__content-information", dangerouslySetInnerHTML: { __html: texts.registrationPageTexts.legalDataSelfEmployedScreen.description } }),
            React.createElement("div", { className: "registration-page__content-fullname" },
                React.createElement("div", { className: "registration-page__content-question registration-page__content-firstname" },
                    React.createElement(RegistrationTextQuestion, { id: 'rlegal_name', content: firstNameTextQuestion, onChange: onChange, fieldName: 'rlegal_name', maxLength: 50 })),
                React.createElement("div", { className: "registration-page__content-question registration-page__content-first-lastname" },
                    React.createElement(RegistrationTextQuestion, { id: 'rlegal_first_surname', content: firstSurnameTextQuestion, onChange: onChange, fieldName: 'rlegal_first_surname', maxLength: 49 })),
                React.createElement("div", { className: "registration-page__content-question registration-page__content-second-lastname" },
                    React.createElement(RegistrationTextQuestion, { id: 'rlegal_last_surname', content: secondSurnameTextQuestion, onChange: onChange, fieldName: 'rlegal_last_surname', maxLength: 49 }))),
            React.createElement("div", { className: "registration-page__content-question" },
                React.createElement(RegistrationTextQuestion, { id: 'rlegal_document_id', content: documentIdTextQuestion, onChange: onChange, fieldName: 'rlegal_document_id', error: nifIsInvalid || isNifDuplicated }),
                nifIsInvalid && (React.createElement("div", { id: "nif-is-invalid", className: "registration-page__content-error_message", "data-testid": "nif-is-invalid" }, texts.registrationPageTexts.validationMessages.invalidFiscalNumber)),
                isNifDuplicated && (React.createElement("div", { id: "nif-is-duplicated", className: "registration-page__content-error_message", "data-testid": "nif-is-duplicated", dangerouslySetInnerHTML: { __html: texts.registrationPageTexts.validationMessages.duplicatedIdentityNumber } }))))));
};
export default LegalDataSelfEmployed;
