import React, { useContext } from 'react';
import FormResponseContext from '../../../../../contexts/FormResponseContext';
import { ContentType } from '../../../../../types/FormModelTypes';
import IterativeResumeItem from './IterativeResumeItem';
import BinaryQuestionButton from '../../../../common/Buttons/BinaryQuestionButton';
import { checkWetherToDisplayQuestionS5C8 } from '../../../../../utils/formSpecificsUtils';
import ContextualHelpModal from '../../../../common/Modals/ContextualHelp/ContextualHelpModal';
import FormProgressContext from '../../../../../contexts/FormProgressContext';
const IterativeResume = ({ content, goToNextContent }) => {
    var _a, _b;
    const { saveFormResponses, getFormResponseById, getUserFormId } = useContext(FormResponseContext);
    const { getCurrentSectionId } = useContext(FormProgressContext);
    const responseExists = !!getFormResponseById(content.id);
    const responseHasIterativeContent = responseExists && ((_a = getFormResponseById(content.id).iterativeContent) === null || _a === void 0 ? void 0 : _a.length) > 0;
    const getIterativeItemsArray = () => {
        if (responseHasIterativeContent) {
            const iterativeContent = getFormResponseById(content.id).iterativeContent;
            const responsesContainingIterativeItems = iterativeContent.flatMap((iterativeResponsesArray) => iterativeResponsesArray.content.find((response) => response.contentId === content.iterativeItems.itemId));
            const additionalIterativeValues = content.iterativeItems.additionalInfoId &&
                iterativeContent
                    .flatMap((iterativeResponsesArray) => iterativeResponsesArray.content.find((r) => r.contentId === content.iterativeItems.additionalInfoId))
                    .map((item) => item.contentValue);
            return responsesContainingIterativeItems.map((iterativeItemResponse, index) => (Object.assign({ contentId: iterativeItemResponse.contentId, value: iterativeItemResponse.contentValue }, (additionalIterativeValues ? { additionalInfo: additionalIterativeValues[index] } : {}))));
        }
    };
    const saveAndGoToNextContent = (questionValue) => {
        const navNextLinkTo = !responseHasIterativeContent && content.questionValues.find((qv) => qv.text === 'No').linkTo;
        const response = {
            contentId: content.id,
            contentValue: questionValue.value,
            valueType: ContentType.BINARY,
            ocs_entity: content.ocs_entity,
            ocs_variable: content.ocs_variable,
            navBackLinkTo: content === null || content === void 0 ? void 0 : content.navBackLinkTo,
            navNextLinkTo,
            iterativeContent: [],
        };
        // Save response to binary question only the first time when arriving on the iterativeResume screen
        !responseHasIterativeContent && saveFormResponses([response], getUserFormId(), content.title, getCurrentSectionId());
        // TODO: refactor this specific case (see if possible to integrate to formModel.json?)
        if (responseHasIterativeContent && questionValue.linkTo === 's5-c3') {
            checkWetherToDisplayQuestionS5C8(getFormResponseById, content, questionValue);
        }
        goToNextContent(questionValue.linkTo);
    };
    return (React.createElement("div", { className: "question iterative-resume-screen" },
        React.createElement("div", { className: "question__body" },
            React.createElement("div", { className: "question__title", "data-testid": "question-title" }, responseHasIterativeContent ? content.titleWhenIterativeItems : content.title)),
        React.createElement("div", { className: "binary-container" },
            content.infoModal && React.createElement(ContextualHelpModal, { modalContent: content.infoModal, contentType: content.type }),
            React.createElement("div", { className: "question__answers-binary", "data-testid": "binary-type-question" }, content.questionValues.map((q) => {
                return React.createElement(BinaryQuestionButton, { onClickFn: () => saveAndGoToNextContent(q), key: q.value, questionValue: q });
            }))),
        responseHasIterativeContent && (React.createElement("div", { className: "iterative-items", "data-testid": "iterative-items" }, (_b = getIterativeItemsArray()) === null || _b === void 0 ? void 0 : _b.map((iterativeItem, index) => {
            return React.createElement(IterativeResumeItem, { item: iterativeItem, index: index, iterativeContentParentId: content.id, key: index });
        })))));
};
export default IterativeResume;
