import React, { createContext, useEffect, useState } from 'react';
import { fetchCompanyInfo } from '../api/userService';
import { useAuth } from './AuthContext';
import { log } from '../utils/logger';
import { changeColorStyle, changeFontType } from '../utils/formSpecificsUtils';
export const UserContext = createContext(null);
export const UserContextProvider = ({ children, activityId, employees }) => {
    const [businessActivityId, setBusinessActivityId] = useState(activityId);
    const [hasEmployees, setHasEmployees] = useState(employees);
    const [collectivePersonalization, setCollectivePersonalization] = useState(null);
    const [isPersonalizationApiCalled, setIsPersonalizationApiCalled] = useState(false);
    const { token, getCompanyId } = useAuth();
    const getUserCompanyId = () => {
        return getCompanyId();
    };
    const getBusinessActivityId = () => {
        return businessActivityId;
    };
    const getHasEmployees = () => {
        return hasEmployees;
    };
    const getLogo = () => {
        return collectivePersonalization === null || collectivePersonalization === void 0 ? void 0 : collectivePersonalization.logoUrl;
    };
    const getPersonalization = () => {
        return collectivePersonalization;
    };
    const getCheckPersonalizationApiCalled = () => {
        return isPersonalizationApiCalled;
    };
    const contextValue = {
        getUserCompanyId,
        getBusinessActivityId,
        getHasEmployees,
        getLogo,
        getPersonalization,
        getCheckPersonalizationApiCalled
    };
    useEffect(() => {
        log.debug('user context effect');
        if (token) {
            fetchCompanyInfo(getCompanyId())
                .then((data) => {
                var _a, _b;
                log.debug('user context effect - fetchCompanyInfo: ', {
                    companyId: getCompanyId(),
                    employees: data.employees,
                    activityId: data.activityId,
                    personalization: data.collectivePersonalization
                });
                setBusinessActivityId(data.activityId);
                setHasEmployees(data.employees > 0);
                setCollectivePersonalization(data.collectivePersonalization);
                if ((_a = data === null || data === void 0 ? void 0 : data.collectivePersonalization) === null || _a === void 0 ? void 0 : _a.colors) {
                    changeColorStyle(data.collectivePersonalization.colors);
                }
                if ((_b = data === null || data === void 0 ? void 0 : data.collectivePersonalization) === null || _b === void 0 ? void 0 : _b.fonts) {
                    changeFontType(data.collectivePersonalization.fonts);
                }
            })
                .catch((err) => log.error('Error calling getCompanyInfo endpoint', err))
                .finally(() => {
                setIsPersonalizationApiCalled(true);
            });
        }
    }, [token, getCompanyId]);
    return React.createElement(UserContext.Provider, { value: contextValue }, children);
};
export default UserContext;
