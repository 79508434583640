import React, { useContext, useState } from 'react';
import FormResponseContext from '../../../../../contexts/FormResponseContext';
import MultiContentQuestions from './MultiContentQuestions';
import NextButton from '../../../../common/Buttons/NextButton';
import { contentIdsForCustomTitle, isLastPageOfIterativeContent } from '../../../../../utils/formSpecificsUtils';
import EipdContext from '../../../../../contexts/EipdContext';
import ContextualHelpModal from '../../../../common/Modals/ContextualHelp/ContextualHelpModal';
import FormProgressContext from '../../../../../contexts/FormProgressContext';
import dataJson from '../../../../../data/formModel.json';
const MultiContentContainer = ({ content, goToNextContent }) => {
    const [selectedResponses, setSelectedResponses] = useState((content === null || content === void 0 ? void 0 : content.formResponses) || []);
    const { getCurrentOt } = useContext(EipdContext);
    const { saveFormResponses, updateFormResponseWithIterativeContent, removeIterativeItem, getUserFormId, addFormResponseHistoryIterativePage } = useContext(FormResponseContext);
    const { getCurrentSectionId } = useContext(FormProgressContext);
    const isNextButtonEnabled = () => {
        const uniqueSelectedResponsesIds = [...new Set(selectedResponses.map((response) => response.contentId))];
        const numberOfCompletedResponsesOnThisPage = uniqueSelectedResponsesIds.filter((responseId) => responseId.replace(/^([^-]+-[^-]+).*$/, '$1') === content.id).length;
        return numberOfCompletedResponsesOnThisPage >= content.content.length;
    };
    const getNextContentId = () => {
        let linkTo;
        if (content.nextButton.behaviour) {
            content.nextButton.behaviour.forEach((behaviour) => {
                behaviour.conditions.forEach((condition) => {
                    if (selectedResponses.find((r) => r.contentId === condition.contentId && r.contentValue === condition.contentValue)) {
                        linkTo = behaviour.linkTo;
                    }
                });
            });
        }
        else if (content.nextButton.linkTo) {
            linkTo = content.nextButton.linkTo;
        }
        return linkTo;
    };
    //Revisar Sitios Web: No guarda bien cuando rellenas todo que si.
    const saveAndGoToNextContent = () => {
        var _a, _b, _c;
        const formSections = dataJson.sections;
        const contentLabels = [];
        if (content.iterativeContentParentId && isLastPageOfIterativeContent(content, selectedResponses)) {
            if (content.id == 's2-c5') {
                const webContentIdsToGetLabel = ['s2-c4-c1', 's2-c4-c2', 's2-c4-c3'];
                const contents = ((_a = formSections.find((fs) => fs.id == 's2')) === null || _a === void 0 ? void 0 : _a.content.find((c) => c.id == 's2-c4')).content;
                webContentIdsToGetLabel.forEach((wc) => contentLabels.push({
                    id: wc,
                    name: contents.find((c) => c.id == wc).label,
                }));
            }
            //Checkear OTs
            if (content.id == 's5-c7') {
                const contentsIdsToGetLabel = ['s5-c4-c1', 's5-c4-c2', 's5-c4-c3', 's5-c5-c1', 's5-c5-c2', 's5-c5-c3', 's5-c5-c4', 's5-c6-c1', 's5-c6-c2', 's5-c6-c3'];
                const contents = (_b = formSections.find((fs) => fs.id == 's5')) === null || _b === void 0 ? void 0 : _b.content.filter((c) => ['s5-c4', 's5-c5', 's5-c6'].includes(c.id));
                contentsIdsToGetLabel.forEach((wc) => contentLabels.push({
                    id: wc,
                    name: contents.find((c) => c.id == wc.split('-').slice(0, 2).join('-')).content.find((c) => c.id == wc).label,
                }));
            }
            updateFormResponseWithIterativeContent(content.iterativeContentParentId, selectedResponses, content.page === 1, getUserFormId(), content.title, getCurrentSectionId(), content, contentLabels);
            setSelectedResponses([]);
        }
        else if (!content.iterativeContentParentId) {
            const parentResponse = {
                contentId: content.id,
                valueType: content.type,
                navBackLinkTo: content.navBackLinkTo,
                navNextLinkTo: (_c = content === null || content === void 0 ? void 0 : content.nextButton) === null || _c === void 0 ? void 0 : _c.linkTo,
            };
            selectedResponses.forEach((x) => (x.navBackLinkTo = content === null || content === void 0 ? void 0 : content.navBackLinkTo));
            selectedResponses.push(parentResponse);
            saveFormResponses(selectedResponses, getUserFormId(), content.title, getCurrentSectionId(), content);
            setSelectedResponses([]);
        }
        goToNextContent(getNextContentId());
    };
    const getIterativeValue = () => {
        var _a, _b;
        return content.title === '[otName]'
            ? (_a = getCurrentOt()) === null || _a === void 0 ? void 0 : _a.title
            : (_b = selectedResponses.find((r) => !!contentIdsForCustomTitle.includes(r.contentId))) === null || _b === void 0 ? void 0 : _b.contentValue;
    };
    const getTitle = () => {
        const titleNeedsReplacement = content.title.includes('[') && content.title.includes(']');
        return titleNeedsReplacement ? content.title.replace(/\[.*?\]/g, getIterativeValue()) : content.title;
    };
    const handleSelectedResponses = (responses) => {
        const contentIdsToMatch = ['s2-c4-c2', 's2-c4-c3', 's2-c4-c1'];
        const modifiedResponses = responses.map((response) => {
            if (contentIdsToMatch.includes(response.contentId)) {
                return Object.assign(Object.assign({}, response), { isSelected: true });
            }
            return response;
        });
        setSelectedResponses(responses);
    };
    const handleCancel = () => {
        removeIterativeItem(content.contentIdForCustomTitle, getIterativeValue(), content.iterativeContentParentId, getUserFormId());
        setSelectedResponses([]);
        goToNextContent(content.cancelButton.linkTo);
    };
    return (React.createElement("div", { className: "multi-content" },
        React.createElement("div", { className: "multi-content__body" },
            React.createElement("h1", { className: `multi-content__title ${content.description && 'no-padding-top'}`, "data-testid": "multi-content-title" }, getTitle()),
            content.infoModal && React.createElement(ContextualHelpModal, { modalContent: content.infoModal, contentType: content.type }),
            content.description && React.createElement("h2", { className: "multi-content__description" }, content.description),
            React.createElement(MultiContentQuestions, { content: content, handleSelectedResponses: handleSelectedResponses, selectedResponses: selectedResponses })),
        React.createElement("div", { className: "multi-content__next-action" },
            content.cancelButton && (React.createElement("button", { onClick: handleCancel, className: "button--cancel", "data-testid": "cancel-button" }, content.cancelButton.title.toUpperCase())),
            React.createElement(NextButton, { onClickFn: saveAndGoToNextContent, enabled: isNextButtonEnabled(), text: content.nextButton.title.toUpperCase() }))));
};
export default MultiContentContainer;
