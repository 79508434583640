import React from 'react';
import SubsectionItem from './SubsectionItem';
import VerticalLine, { VerticalLinePositionEnum } from './VerticalLine';
const SectionItem = ({ menuSection, currentSectionId, completedSectionsIds }) => {
    const hasSubsections = menuSection.subsections.length > 0;
    const isCurrentSection = menuSection.id === currentSectionId;
    const isCurrentSubsection = menuSection.subsections.filter((subsection) => subsection.id == currentSectionId).length > 0;
    const isPreviousSectionCompleted = () => {
        return completedSectionsIds.includes(menuSection.previousSectionId);
    };
    const renderSubsections = () => {
        return menuSection.subsections.map((subsection) => {
            return React.createElement(SubsectionItem, { key: subsection.id, subsection: subsection, currentSectionId: currentSectionId });
        });
    };
    return (React.createElement("div", { "data-testid": 'section' },
        React.createElement("div", { className: "section-item" },
            React.createElement("div", { className: "section-item__progress" },
                React.createElement(VerticalLine, { position: VerticalLinePositionEnum.UP, transparent: menuSection.isFirstSection, previousSectionCompleted: isPreviousSectionCompleted() }),
                React.createElement("i", { className: `bi
           ${menuSection.isCompleted ? 'bi-check-circle-fill' : isCurrentSection || isCurrentSubsection ? 'bi-check-circle' : 'bi-circle'}
            section-item__icon` }),
                React.createElement(VerticalLine, { position: VerticalLinePositionEnum.DOWN, transparent: menuSection.isLastSection && !((hasSubsections && isCurrentSection) || isCurrentSubsection), sectionCompleted: menuSection.isCompleted })),
            React.createElement("p", { "data-testid": "menu-section-title", className: `section-item__text
          ${isCurrentSection || isCurrentSubsection ? 'section-item__text--active' : 'section-item__text'}
          ${menuSection.isCompleted ? 'section-item__text--completed' : 'section-item__text'}` }, menuSection.sectionTitle)),
        React.createElement("div", { className: menuSection.isLastSection ? 'subsection-items--end' : 'subsection-items' }, ((isCurrentSection && hasSubsections) || isCurrentSubsection) && renderSubsections())));
};
export default SectionItem;
