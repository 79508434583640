import React from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';
import Login from '../components/pages/Login/Login';
import Form from '../components/pages/Form/Form';
import dataJson from '../data/formModel.json';
import { useAuth } from '../contexts/AuthContext';
import Documentation from '../components/pages/Documentation/Documentation';
import Registration from '../components/pages/Registration/Registration';
import { FormProgressContextProvider } from '../contexts/FormProgressContext';
import { FormResponseContextProvider } from '../contexts/FormResponseContext';
import { EipdContextProvider } from '../contexts/EipdContext';
import ResetPassword from '../components/pages/ResetPassword/ResetPassword';
import ErrorPage, { ErrorEnum } from '../components/pages/Error/ErrorPage';
import { ErrorBoundary } from 'react-error-boundary';
import ContactInfoModal from '../components/common/Modals/ContactInfo/ContactInfoModal';
import ContactInfoModalWithResetLink from '../components/common/Modals/ContactInfo/ContactInfoModalWithResetLink';
import { DpdContextProvider } from '../contexts/DpdContext';
import { useMaintenance } from '../contexts/MaintenanceContext';
import MaintenancePage from '../components/pages/Manteinance/MaintenancePage';
const Routes = () => {
    const { token } = useAuth();
    const { isDeploying } = useMaintenance();
    const data = dataJson;
    const routesForPublic = [
        {
            path: '*',
            element: React.createElement(ErrorPage, { error: ErrorEnum.PAGE_NOT_FOUND }),
        },
        {
            path: '/maintenance-test-it',
            element: React.createElement(MaintenancePage, null),
        },
    ];
    const routesForMaintenance = [
        {
            path: '/',
            element: React.createElement(MaintenancePage, null),
        },
        {
            path: '*',
            element: React.createElement(Navigate, { to: "/", replace: true }),
        },
    ];
    const routesForAuthenticatedOnly = [
        {
            path: '/',
            element: React.createElement(ProtectedRoute, null),
            children: [
                {
                    path: '/',
                    element: (React.createElement(ErrorBoundary, { fallback: React.createElement(ErrorPage, { error: ErrorEnum.GENERIC }) },
                        React.createElement(FormResponseContextProvider, null,
                            React.createElement(FormProgressContextProvider, { sectionsData: data.sections },
                                React.createElement(EipdContextProvider, null,
                                    React.createElement(ContactInfoModalWithResetLink, null),
                                    React.createElement(DpdContextProvider, null,
                                        React.createElement(Form, null))))))),
                },
                {
                    path: '/form',
                    element: (React.createElement(ErrorBoundary, { fallback: React.createElement(ErrorPage, { error: ErrorEnum.GENERIC }) },
                        React.createElement(FormResponseContextProvider, null,
                            React.createElement(FormProgressContextProvider, { sectionsData: data.sections },
                                React.createElement(EipdContextProvider, null,
                                    React.createElement(ContactInfoModalWithResetLink, null),
                                    React.createElement(DpdContextProvider, null,
                                        React.createElement(Form, null))))))),
                },
                {
                    path: '/documentation',
                    element: (React.createElement(React.Fragment, null,
                        React.createElement(ContactInfoModal, null),
                        React.createElement(Documentation, null))),
                },
            ],
            errorElement: React.createElement(ErrorPage, { error: ErrorEnum.GENERIC }),
        },
    ];
    const routesForNotAuthenticatedOnly = [
        {
            path: '/',
            element: (React.createElement(ErrorBoundary, { fallback: React.createElement(ErrorPage, { error: ErrorEnum.GENERIC }) },
                React.createElement(ContactInfoModal, null),
                React.createElement(Login, null))),
            errorElement: React.createElement(ErrorPage, { error: ErrorEnum.GENERIC }),
        },
        {
            path: '/login',
            element: (React.createElement(ErrorBoundary, { fallback: React.createElement(ErrorPage, { error: ErrorEnum.GENERIC }) },
                React.createElement(ContactInfoModal, null),
                React.createElement(Login, null))),
            errorElement: React.createElement(ErrorPage, { error: ErrorEnum.GENERIC }),
        },
        {
            path: '/registro',
            element: (React.createElement(ErrorBoundary, { fallback: React.createElement(ErrorPage, { error: ErrorEnum.GENERIC }) },
                React.createElement(ContactInfoModal, null),
                React.createElement(Registration, null))),
            errorElement: React.createElement(ErrorPage, { error: ErrorEnum.GENERIC }),
        },
        {
            path: '/reseteo',
            element: (React.createElement(ErrorBoundary, { fallback: React.createElement(ErrorPage, { error: ErrorEnum.GENERIC }) },
                React.createElement(ContactInfoModal, null),
                React.createElement(ResetPassword, null))),
            errorElement: React.createElement(ErrorPage, { error: ErrorEnum.GENERIC }),
        },
    ];
    const routesWithoutDeployment = [...routesForPublic, ...(!token ? routesForNotAuthenticatedOnly : []), ...routesForAuthenticatedOnly];
    const routeWithDeployment = [...routesForMaintenance];
    // Combine and conditionally include routes based on authentication status
    const router = createBrowserRouter(isDeploying ? routeWithDeployment : routesWithoutDeployment);
    return React.createElement(RouterProvider, { router: router });
};
export default Routes;
