import React, { useContext, useEffect, useState } from 'react';
import { fetchGeneratedActivos, getPendingEipdOTs } from '../../../../../api/signoAPI';
import NextButton from '../../../../common/Buttons/NextButton';
import OtItem from './OtItem';
import texts from '../../../../../data/texts.json';
import FormResponseContext from '../../../../../contexts/FormResponseContext';
import EipdContext from '../../../../../contexts/EipdContext';
import { log } from '../../../../../utils/logger';
const IterativeOts = ({ content, goToNextContent }) => {
    const [pendingOts, setPendingOts] = useState([]);
    const [hasCompletedAllOts, setHasCompletedAllOts] = useState(false);
    const { getGeneratedActivos, saveGeneratedActivos } = useContext(EipdContext);
    const { getUserFormId } = useContext(FormResponseContext);
    useEffect(() => {
        getPendingEipdOTs(getUserFormId())
            .then(({ data }) => {
            setPendingOts(data);
            if (data.length > 0) {
                setHasCompletedAllOts(false);
            }
            else {
                setHasCompletedAllOts(true);
            }
        })
            .catch((error) => log.debug(error));
        if (getGeneratedActivos() === null) {
            fetchGeneratedActivos(getUserFormId())
                .then(({ data }) => {
                saveGeneratedActivos(data);
            })
                .catch((e) => log.debug(e));
        }
    }, [getUserFormId, getGeneratedActivos, saveGeneratedActivos]);
    const getSectionTitle = (pendingActions) => {
        return (pendingActions === null || pendingActions === void 0 ? void 0 : pendingActions.length) === 1 ? texts.iterativeOtsTexts.subtitle.replace(/\bpendientes\b/, 'pendiente') : texts.iterativeOtsTexts.subtitle;
    };
    return (React.createElement("div", { className: "iterative-ots-page" },
        React.createElement("h1", { className: "iterative-ots-page__title" }, content.title),
        React.createElement("div", { className: "iterative-ots-page__body" },
            (pendingOts === null || pendingOts === void 0 ? void 0 : pendingOts.length) > 0 && (React.createElement("h3", { className: "iterative-ots-page__section-title", "data-testid": "iterative-ots-section-title" }, pendingOts === null || pendingOts === void 0 ? void 0 :
                pendingOts.length,
                " ",
                getSectionTitle(pendingOts))),
            hasCompletedAllOts ? (React.createElement("div", { className: "alert-message alert alert-success", role: "alert", "data-testid": "no-pending-actions-success-messsage" },
                React.createElement("i", { className: "bi bi-check" }),
                React.createElement("span", null, texts.iterativeOtsTexts.successMessage))) : (pendingOts && (React.createElement("div", { className: "iterative-items", "data-testid": "iterative-items" }, pendingOts.map((ot, index) => {
                return React.createElement(OtItem, { ot: ot, index: index, goToComplete: () => goToNextContent(content.completeLinkTo), key: index });
            }))))),
        React.createElement("div", { className: "iterative-ots-page__next-action" },
            React.createElement(NextButton, { onClickFn: () => goToNextContent(content.nextButton.linkTo), text: content.nextButton.title.toUpperCase(), enabled: hasCompletedAllOts }))));
};
export default IterativeOts;
