import React from 'react';
import DisclaimerContainer from '../DisclaimerContainer';
import texts from '../../../data/texts.json';
import { Helmet } from 'react-helmet';
export var ErrorEnum;
(function (ErrorEnum) {
    ErrorEnum["GENERIC"] = "genericError";
    ErrorEnum["REGISTRATION_INVALID_COLLECTIVE"] = "registrationInvalidCollectiveIdError";
    ErrorEnum["REGISTRATION_ERROR"] = "registrationError";
    ErrorEnum["PASSWORD_RESET"] = "passwordResetError";
    ErrorEnum["PAGE_NOT_FOUND"] = "pageNotFoundError";
})(ErrorEnum || (ErrorEnum = {}));
const ErrorPage = ({ error }) => {
    const getDescription = () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return { __html: texts.errorPageTexts[error].description };
    };
    const getSubdescription = () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return { __html: texts.errorPageTexts[error].subdescription };
    };
    return (React.createElement(DisclaimerContainer, null,
        React.createElement(Helmet, null,
            React.createElement("title", null,
                "Signo | ",
                texts.errorPageTexts.pageTitle)),
        React.createElement("h1", { className: "disclaimer-page__title", "data-testid": "error-page-title" }, texts.errorPageTexts.pageTitle),
        React.createElement("div", { className: "disclaimer-page__description", "data-testid": "error-page-description", dangerouslySetInnerHTML: getDescription() }),
        getSubdescription() !== undefined && (React.createElement("div", { className: "disclaimer-page__subdescription", "data-testid": "error-page-subdescription", dangerouslySetInnerHTML: getSubdescription() }))));
};
export default ErrorPage;
